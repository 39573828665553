import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  customStyles, setTheme, StepContainer, Select, TreeRow, Row2, SmallContent, Question,
} from './orderStyledComponents';
import { mobileThresholdPixels } from '../styledComponents';
import { quantityQuestionsLabels } from './treeUpcycling';
import ProgressBarVertical from '../order/ProgressBarVertical';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 243px;
    justify-content: center;
  }
`;

const UpcyclingItemQuantitySelector = ({ createRange, slug, id, setUpcyclingItemQuantity, quantity, isLast }) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical
        isActive
        height={142}
        noLine={isLast}
      />
      <SmallContent flexStart marginLeft>
        <Question>{quantityQuestionsLabels[slug]}</Question>
        <Row2>
          <CardsContainer>
            <Select
              placeholder=""
              value={quantity}
              onChange={value => setUpcyclingItemQuantity(id, value)}
              options={createRange(1, 8)}
              styles={customStyles}
              theme={theme => setTheme(theme)}
            />
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

UpcyclingItemQuantitySelector.propTypes = {
  createRange: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  setUpcyclingItemQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.shape({}).isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default UpcyclingItemQuantitySelector;
