import React from 'react';
import PropTypes from 'prop-types';

import SuitPiecesSelector from './SuitPiecesSelector';
import SuitProblemsSelector from './SuitProblemsSelector';
import SuitItemsSelector from './SuitItemsSelector';
import SuitItemQuantitySelector from './Step1-5Multiple';
import SuitLiningSelector from './Step1-6Lining';
import treeAlterationSuit from './treeAlterationSuit';

const shouldDisplayLining = (suitPiece, problems, items) => {
  if (problems.length === 0) return false;
  const problemsToCheckForLining = problems
    .filter(problem => problem.includes(`${suitPiece}_`))
    .map(problem => problem.replace(`${suitPiece}_`, ''))
    .filter(problem => !!treeAlterationSuit.pieces[suitPiece].problems[problem].answers);

  if (problemsToCheckForLining.length === 0) return false;

  for (let i = 0; i < problemsToCheckForLining.length; i += 1) {
    const problemToCheck = problemsToCheckForLining[i];
    const problemItems = items.filter(({ piece, problem }) => piece === suitPiece && problem === problemToCheck);
    if (problemItems.length === 0) return false;
  }

  return true;
};

const renderSuitLiningSelector = (suitPieces, suitPiece, suitPieceIndex, problems, items, suitPieceLinings, selectSuitPieceLining) => {
  if (!shouldDisplayLining(suitPiece, problems, items)) return null;
  return (
    <SuitLiningSelector
      selectedLining={suitPieceLinings[suitPiece]}
      selectLining={haveLining => selectSuitPieceLining(suitPiece, haveLining)}
      isLast={suitPieceIndex === suitPieces.length - 1}
    />
  );
};

const renderSuitItemsSelector = (piece, problem, items, toggleSuitItem) => (
  <SuitItemsSelector
    key={problem}
    suitPiece={piece}
    suitProblem={problem}
    suitItems={items}
    toggleSuitItem={toggleSuitItem}
  />
);


const SuitSelector = ({
  suitPieces, toggleSuitPiece, suitProblems, toggleSuitProblem, suitItems,
  toggleSuitItem, setSuitItemQuantity, createRange, suitPieceLinings, selectSuitPieceLining,
}) => (
  <>
    <SuitPiecesSelector
      suitPieces={suitPieces}
      toggleSuitPiece={toggleSuitPiece}
    />
    {suitPieces.map((suitPiece, suitPieceIndex) => {
      const suitProblemsForCurrentPiece = suitProblems
        .filter(suitProblem => suitProblem.includes(`${suitPiece}_`)
          && treeAlterationSuit.pieces[suitPiece].problems[suitProblem.replace(`${suitPiece}_`, '')].answers?.length > 1);
      return (
        <React.Fragment key={suitPiece}>
          <SuitProblemsSelector
            suitPiece={suitPiece}
            suitProblems={suitProblems}
            toggleSuitProblem={toggleSuitProblem}
            isLast={suitPieceIndex === suitPieces.length - 1}
          />
          {suitProblemsForCurrentPiece
            .map((suitProblem) => {
              const suitProblemSlug = suitProblem.replace(`${suitPiece}_`, '');
              const suitItemsForCurrentProblem = suitItems.filter(({ piece, problem }) => piece === suitPiece && problem === suitProblemSlug);
              return (
                <React.Fragment key={suitProblem}>
                  {renderSuitItemsSelector(suitPiece, suitProblemSlug, suitItemsForCurrentProblem, toggleSuitItem)}
                  {suitItemsForCurrentProblem.filter(({ isMultiple }) => isMultiple)
                    .map(suitItem => (
                      <SuitItemQuantitySelector
                        key={suitItem.id}
                        createRange={createRange}
                        slug={suitItem.slug}
                        itemId={suitItem.id}
                        selectedValue={suitItem.quantity}
                        setSelectValue={setSuitItemQuantity}
                      />
                    ))}
                </React.Fragment>
              );
            })}
          {renderSuitLiningSelector(suitPieces, suitPiece, suitPieceIndex, suitProblems, suitItems, suitPieceLinings, selectSuitPieceLining)}
        </React.Fragment>
      );
    })}
  </>
);

SuitSelector.propTypes = {
  suitPieces: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSuitPiece: PropTypes.func.isRequired,
  suitProblems: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSuitProblem: PropTypes.func.isRequired,
  suitItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleSuitItem: PropTypes.func.isRequired,
  setSuitItemQuantity: PropTypes.func.isRequired,
  createRange: PropTypes.func.isRequired,
  suitPieceLinings: PropTypes.shape({}),
  selectSuitPieceLining: PropTypes.func,
};

export default SuitSelector;
