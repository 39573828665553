import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';

import { mobileThresholdPixels } from '../styledComponents';
import { colors as colorsV3 } from '../home/v3/styledComponents';

import CategorySelector from './CategorySelector';
import Step1Pieces from './Step1-1Pieces';
import Step1RDV3Clicks from './Step1-1RDV3Clicks';
import Step1Fabrics from './Step1-2Fabrics';
import Step1Tree from './Step1-3Tree';
import Step1AccessoryCreations from './Step1-4AccessoryCreations';
import Step1UpcyclingInspirations from './Step1-4UpcyclingInspirations';
import Step1Problems from './Step1-4Problems';
import Step1Linens from './Step1-4Linens';
import Step1Where from './Step1-5Where';
import Step1Multiple from './Step1-5Multiple';
import Step1Curtains from './Step1-2Curtains';
import Step1Cushions from './Step1-2Cushions';
import Step1Creations from './Step1-5Creations';
import Step1Lining from './Step1-6Lining';
import Step1Embroideries from './Step1-2Embroideries';
import SuitSelector from './SuitSelector';
import Step1FabricsListOfAccessoriesCreation from './Step1-FabricsListOfAccessoriesCreation';
import WeListenToYouHeader from './WeListenToYouHeader';
import ComponentOrEmptyDiv from './ComponentOrEmptyDiv';
import treeUpcycling from './treeUpcycling';
import UpcyclingSelector from './UpcyclingSelector';

import overallPhoto from '../../assets/order/retouches/overall.jpg';
import pantsPhoto from '../../assets/order/retouches/pants.jpg';
import coatPhoto from '../../assets/order/retouches/coat.jpg';
import dressPhoto from '../../assets/order/retouches/dress.jpg';
import shirtPhoto from '../../assets/order/retouches/shirt.jpg';
import skirtPhoto from '../../assets/order/retouches/skirt.jpg';
import pulloverPhoto from '../../assets/order/retouches/pullover.jpg';
import shortsPhoto from '../../assets/order/retouches/shorts.jpg';
import weddingPhoto from '../../assets/order/retouches/wedding.jpg';
import curtainPhoto from '../../assets/order/retouches/curtain.jpg';
import cushionPhoto from '../../assets/order/retouches/cushion.jpg';
import linensPhoto from '../../assets/order/retouches/linens.jpg';
import lingeriePhoto from '../../assets/order/retouches/lingerie.jpg';
import accessoriesPhoto from '../../assets/order/retouches/accessories.jpg';
import suitPhoto from '../../assets/order/retouches/suit.jpg';
import bagPhoto from '../../assets/order/creations/bag.jpg';
import toteBagPhoto from '../../assets/order/creations/toteBag.jpg';
import clutchBagPhoto from '../../assets/order/creations/clutchBag.jpg';
import cushionCreationPhoto from '../../assets/order/creations/cushion.jpg';
import cotonEcruBalzac from '../../assets/order/creations/fabrics/coton-ecru-balzac.jpg';
import cotonNbBalzac from '../../assets/order/creations/fabrics/coton-nb-balzac.jpg';
import cotonRougeBalzac from '../../assets/order/creations/fabrics/coton-rouge-balzac.jpg';
import fleursCyrillus from '../../assets/order/creations/fabrics/fleurs-cyrillus.jpg';
import bleuJacquardFrancais from '../../assets/order/creations/fabrics/bleu-jacquard-francais.jpg';
import groseillesCyrillus from '../../assets/order/creations/fabrics/groseilles-cyrillus.jpg';
import cotonBeigeMadura from '../../assets/order/creations/fabrics/coton-beige-madura.jpg';

const Relative = styled.div`
  position: relative;
`;

const ScrollingPosition = styled.div`
  position: absolute;
  top: ${props => props.lower ? '-103px' : '-43px'};
`;

const OpacityContainer = styled.div`
  background: ${colorsV3.opacityFilter};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;

const Container = styled.div`
  width: 100%;
  ${props => props.marginBottom && `
    margin-bottom: -700px;
    @media (max-width: ${mobileThresholdPixels}) {
      margin-bottom: -850px;
    }
  `}
`;

const clothPieces = [
  { name: 'Pantalon', slug: 'pant', image: pantsPhoto },
  { name: 'Manteau & Veste', slug: 'mant', image: coatPhoto },
  { name: 'Robe', slug: 'robe', image: dressPhoto },
  { name: 'Haut', slug: 'haut', image: shirtPhoto },
  { name: 'Costume', slug: 'cost', image: suitPhoto },
  { name: 'Robe de mariée', slug: 'mari', image: weddingPhoto },
  { name: 'Pull & gilet', slug: 'pull', image: pulloverPhoto },
  { name: 'Jupe', slug: 'jupe', image: skirtPhoto },
  { name: 'Combinaison', slug: 'comb', image: overallPhoto },
  { name: 'Maillot de bain', slug: 'mail', image: shortsPhoto },
  { name: 'Lingerie', slug: 'ling', image: lingeriePhoto },
  { name: 'Accessoires', slug: 'acce', image: accessoriesPhoto },
];

const decoPieces = [
  { name: 'Rideau', slug: 'ride', image: curtainPhoto },
  { name: 'Housse', slug: 'cous', image: cushionPhoto },
  { name: 'Linge de maison', slug: 'mais', image: linensPhoto },
];

const accessoryCreations = [
  { name: 'Sac', slug: 'bag', image: bagPhoto },
  { name: 'Tote bag', slug: 'toteBag', image: toteBagPhoto },
  { name: 'Pochette', slug: 'clutchBag', image: clutchBagPhoto, noHandleOption: true },
  { name: 'Housse', slug: 'cou2', image: cushionCreationPhoto },
];

const fabricsListOfAccessoriesCreation = [
  {
    name: 'Coton écru de Balzac',
    slug: 'coton-ecru-balzac',
    image: cotonEcruBalzac,
  },
  {
    name: 'Coton N&B de Balzac',
    slug: 'coton-nb-balzac',
    image: cotonNbBalzac,
  },
  {
    name: 'Coton rouge de Balzac',
    slug: 'coton-rouge-balzac',
    image: cotonRougeBalzac,
  },
  {
    name: 'Les fleurs de Cyrillus',
    slug: 'fleurs-cyrillus',
    image: fleursCyrillus,
  },
  {
    name: 'Le bleu du Jacquard Français',
    slug: 'bleu-jacquard-francais',
    image: bleuJacquardFrancais,
  },
  {
    name: 'Les groseilles de Cyrillus',
    slug: 'groseilles-cyrillus',
    image: groseillesCyrillus,
  },
  {
    name: 'Coton beige de Madura',
    slug: 'coton-beige-madura',
    image: cotonBeigeMadura,
  },
];

const createRange = (min, max) => {
  const range = [];
  for (let i = min; i < max + 1; i += 1) {
    range.push({ value: i, label: i.toString() });
  }
  return range;
};

const renderRelative = (setRef, key, lower = false) => (
  <Relative>
    <ScrollingPosition lower={lower} ref={component => setRef(key, component)} />
  </Relative>
);

const renderRDV3Clicks = ({ selectedCategory, selectCategory }) => (
  <ComponentOrEmptyDiv mustShow={selectedCategory === 'RDV3Clicks'}>
    <Step1RDV3Clicks unsetCategory={() => selectCategory('')} />
    <OpacityContainer />
  </ComponentOrEmptyDiv>
);

renderRDV3Clicks.propTypes = {
  selectedCategory: PropTypes.string,
  selectCategory: PropTypes.func,
};

const renderTypeOfWorkSelector = ({
  selectTypeOfWork, selectedCloth, selectedTypeOfWork,
}) => (
  <Step1Tree
    selectTypeOfWork={slug => selectTypeOfWork(slug)}
    selectedCloth={selectedCloth}
    selectedTypeOfWork={selectedTypeOfWork}
  />
);

renderTypeOfWorkSelector.propTypes = {
  selectTypeOfWork: PropTypes.func,
  selectedCloth: PropTypes.string,
  selectedTypeOfWork: PropTypes.string,
};

const renderPieceSelector = ({
  selectedTypeOfWork, selectedCategory, selectCloth, selectPiece, selectedCloth, selectedPiece,
}) => {
  const isCloth = selectedCategory === 'clothes';
  return (
    <ComponentOrEmptyDiv mustShow={!!selectedTypeOfWork && selectedTypeOfWork !== 'creation'}>
      <Step1Pieces
        center={!isCloth}
        pieces={isCloth ? clothPieces : decoPieces}
        selectPiece={isCloth ? selectCloth : selectPiece}
        selectedPiece={isCloth ? selectedCloth : selectedPiece}
      />
    </ComponentOrEmptyDiv>
  );
};

renderPieceSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedCategory: PropTypes.string,
  selectCloth: PropTypes.func,
  selectPiece: PropTypes.func,
  selectedCloth: PropTypes.string,
  selectedPiece: PropTypes.string,
};

const renderAccessoryCreationSelector = ({
  selectedTypeOfWork, selectAccessoryCreation, selectedAccessoryCreation,
}) => (
  <ComponentOrEmptyDiv mustShow={selectedTypeOfWork === 'creation'}>
    <Step1AccessoryCreations
      accessoryCreations={accessoryCreations}
      selectAccessoryCreation={selectAccessoryCreation}
      selectedAccessoryCreation={selectedAccessoryCreation}
    />
  </ComponentOrEmptyDiv>
);

renderAccessoryCreationSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectAccessoryCreation: PropTypes.func,
  selectedAccessoryCreation: PropTypes.string,
};

const renderFabricSelector = ({
  selectedTypeOfWork, selectFabric, selectedFabric, selectedCloth, isFurSelected,
}) => {
  const mustShow = !!selectedTypeOfWork && selectedTypeOfWork !== 'creation'
    && !!selectedCloth && !['mari', 'pull', 'mail', 'ling', 'cost'].includes(selectedCloth);
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      <Step1Fabrics
        selectFabric={slug => selectFabric(slug)}
        selectedFabric={selectedFabric}
        selectedCloth={selectedCloth}
        isFurSelected={isFurSelected}
      />
    </ComponentOrEmptyDiv>
  );
};

renderFabricSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectFabric: PropTypes.func,
  selectedFabric: PropTypes.string,
  selectedCloth: PropTypes.string,
  isFurSelected: PropTypes.bool,
};

const renderSuitSelector = ({
  selectedTypeOfWork, selectedCloth, suitPieces, toggleSuitPiece, suitProblems, toggleSuitProblem,
  suitItems, toggleSuitItem, setSuitItemQuantity, suitPieceLinings, selectSuitPieceLining,
}) => (
  <ComponentOrEmptyDiv mustShow={selectedTypeOfWork === 'alteration' && selectedCloth === 'cost'}>
    <WeListenToYouHeader />
    <SuitSelector
      suitPieces={suitPieces}
      toggleSuitPiece={toggleSuitPiece}
      suitProblems={suitProblems}
      toggleSuitProblem={toggleSuitProblem}
      suitItems={suitItems}
      toggleSuitItem={toggleSuitItem}
      setSuitItemQuantity={setSuitItemQuantity}
      createRange={createRange}
      suitPieceLinings={suitPieceLinings}
      selectSuitPieceLining={selectSuitPieceLining}
    />
  </ComponentOrEmptyDiv>
);

renderSuitSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedCloth: PropTypes.string,
  suitPieces: PropTypes.arrayOf(PropTypes.string),
  toggleSuitPiece: PropTypes.func,
  suitProblems: PropTypes.arrayOf(PropTypes.string),
  toggleSuitProblem: PropTypes.func,
  suitItems: PropTypes.arrayOf(PropTypes.shape({})),
  toggleSuitItem: PropTypes.func,
  setSuitItemQuantity: PropTypes.func,
  suitPieceLinings: PropTypes.shape({}),
  selectSuitPieceLining: PropTypes.func,
};

const renderAlterationProblemsSelector = ({
  selectedTypeOfWork, selectedPiece, selectedFabric, selectedProblems,
  selectProblems, getTreeLocations, treeProblems, selectedCloth,
}) => {
  const mustShow = selectedTypeOfWork === 'alteration' && !selectedPiece && !!treeProblems
    && (!!selectedFabric || ['mari', 'pull', 'mail', 'ling'].includes(selectedCloth));
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      {selectedCloth !== 'cost' && <WeListenToYouHeader />}
      <Step1Problems
        selectedProblems={selectedProblems}
        selectProblems={slug => selectProblems(slug, getTreeLocations(slug))}
        treeProblems={treeProblems}
        selectedCloth={selectedCloth}
      />
    </ComponentOrEmptyDiv>
  );
};

renderAlterationProblemsSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectProblems: PropTypes.func,
  selectedFabric: PropTypes.string,
  selectedPiece: PropTypes.string,
  selectedCloth: PropTypes.string,
  selectedProblems: PropTypes.shape({}),
  getTreeLocations: PropTypes.func,
  treeProblems: PropTypes.shape({}),
};

const renderCurtainQuestionsSelector = (
  setSelect, setSingleChoice, setMultipleChoices,
  numberOfPieces, isCurtainLined, curtainAlterations, activeCurtainAdjustment,
) => (
  <div>
    <WeListenToYouHeader />
    <Step1Curtains
      createRange={createRange}
      setSelect={setSelect}
      setSingleChoice={setSingleChoice}
      setMultipleChoices={setMultipleChoices}
      numberOfPieces={numberOfPieces}
      isLined={isCurtainLined}
      alterations={curtainAlterations}
      activeAdjustment={activeCurtainAdjustment}
    />
  </div>
);

const renderCushionQuestionsSelector = (
  setSelect, setSingleChoice, setMultipleChoices, numberOfPieces,
  isCushionSmall, cushionHasZip, cushionAlterations, isCushionAlterationSelected,
) => (
  <div>
    <WeListenToYouHeader />
    <Step1Cushions
      createRange={createRange}
      setSelect={setSelect}
      setSingleChoice={setSingleChoice}
      setMultipleChoices={setMultipleChoices}
      numberOfPieces={numberOfPieces}
      isSmall={isCushionSmall}
      hasZip={cushionHasZip}
      alterations={cushionAlterations}
      isAlterationSelected={isCushionAlterationSelected}
    />
  </div>
);

const renderLinenQuestionsSelector = (
  setSelect, setSingleChoice, setMultipleChoices,
  linensProblems, linensAlterations, linensSizes, linensWhere,
) => (
  <div>
    <WeListenToYouHeader />
    <Step1Linens
      createRange={createRange}
      setSelect={setSelect}
      setSingleChoice={setSingleChoice}
      setMultipleChoices={setMultipleChoices}
      problems={linensProblems}
      alterations={linensAlterations}
      sizes={linensSizes}
      where={linensWhere}
    />
  </div>
);

const renderDecoQuestionsContainer = ({
  selectedTypeOfWork, selectedPiece, setSelect, setSingleChoice, setMultipleChoices,
  numberOfPieces, isCurtainLined, curtainAlterations, activeCurtainAdjustment,
  isCushionSmall, cushionHasZip, cushionAlterations, isCushionAlterationSelected,
  linensProblems, linensAlterations, linensSizes, linensWhere,
}) => {
  if (selectedTypeOfWork === 'alteration' && selectedPiece === 'ride') {
    return (renderCurtainQuestionsSelector(
      setSelect, setSingleChoice, setMultipleChoices,
      numberOfPieces, isCurtainLined, curtainAlterations, activeCurtainAdjustment,
    ));
  }
  if (selectedTypeOfWork === 'alteration' && selectedPiece === 'cous') {
    return (renderCushionQuestionsSelector(
      setSelect, setSingleChoice, setMultipleChoices, numberOfPieces,
      isCushionSmall, cushionHasZip, cushionAlterations, isCushionAlterationSelected,
    ));
  }
  if (selectedTypeOfWork === 'alteration' && selectedPiece === 'mais') {
    return (renderLinenQuestionsSelector(
      setSelect, setSingleChoice, setMultipleChoices,
      linensProblems, linensAlterations, linensSizes, linensWhere,
    ));
  }
  return <div />;
};

renderDecoQuestionsContainer.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedPiece: PropTypes.string,
  setSelect: PropTypes.func,
  setSingleChoice: PropTypes.func,
  setMultipleChoices: PropTypes.func,
  numberOfPieces: PropTypes.number,
  isCurtainLined: PropTypes.bool,
  curtainAlterations: PropTypes.arrayOf(PropTypes.shape({})),
  activeCurtainAdjustment: PropTypes.number,
  isCushionSmall: PropTypes.bool,
  cushionHasZip: PropTypes.bool,
  isCushionAlterationSelected: PropTypes.bool,
  cushionAlterations: PropTypes.arrayOf(PropTypes.shape({})),
  linensProblems: PropTypes.arrayOf(PropTypes.shape({})),
  linensAlterations: PropTypes.arrayOf(PropTypes.shape({})),
  linensSizes: PropTypes.arrayOf(PropTypes.shape({})),
  linensWhere: PropTypes.arrayOf(PropTypes.shape({})),
};

const renderEmbroideriesSelector = ({
  selectedTypeOfWork, selectedPiece, selectedCloth, setSingleChoice, setMultipleChoices,
  embroideries, activeEmbroideryFontSize, activeEmbroideryDrawingSize,
  isDrawingEasy, isDrawingFull, displaySummary,
}) => {
  const mustShow = selectedTypeOfWork === 'embroidery' && (!!selectedPiece || !!selectedCloth);
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      <WeListenToYouHeader />
      <Step1Embroideries
        pieceSlug={selectedCloth}
        setSingleChoice={setSingleChoice}
        setMultipleChoices={setMultipleChoices}
        embroideries={embroideries}
        activeEmbroideryFontSize={activeEmbroideryFontSize}
        activeEmbroideryDrawingSize={activeEmbroideryDrawingSize}
        isDrawingFull={isDrawingFull}
        isDrawingEasy={isDrawingEasy}
        displaySummary={displaySummary}
      />
    </ComponentOrEmptyDiv>
  );
};

renderEmbroideriesSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedPiece: PropTypes.string,
  selectedCloth: PropTypes.string,
  setSingleChoice: PropTypes.func,
  setMultipleChoices: PropTypes.func,
  embroideries: PropTypes.arrayOf(PropTypes.shape({})),
  activeEmbroideryFontSize: PropTypes.number,
  activeEmbroideryDrawingSize: PropTypes.number,
  isDrawingFull: PropTypes.bool,
  isDrawingEasy: PropTypes.bool,
  displaySummary: PropTypes.bool,
};

const renderUpcyclingInspirationsContainer = ({
  selectedTypeOfWork, selectedCloth, selectedFabric,
}) => {
  const mustShow = selectedTypeOfWork === 'transformation' && !!selectedCloth;
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      <Step1UpcyclingInspirations
        selectedCloth={selectedCloth}
        selectedFabric={selectedFabric}
      />
    </ComponentOrEmptyDiv>
  );
};

renderUpcyclingInspirationsContainer.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedCloth: PropTypes.string,
  selectedFabric: PropTypes.string,
};

const renderAccessoryCreationFabricsSelector = ({
  selectedAccessoryCreation, selectFabricOfAccessoriesCreation, selectedFabricOfAccessoriesCreation,
}) => (
  <ComponentOrEmptyDiv mustShow={!!selectedAccessoryCreation}>
    <Step1FabricsListOfAccessoriesCreation
      fabricsListOfAccessoriesCreation={fabricsListOfAccessoriesCreation}
      selectFabricOfAccessoriesCreation={selectFabricOfAccessoriesCreation}
      selectedFabricOfAccessoriesCreation={selectedFabricOfAccessoriesCreation}
    />
  </ComponentOrEmptyDiv>
);

renderAccessoryCreationFabricsSelector.propTypes = {
  selectedAccessoryCreation: PropTypes.string,
  selectFabricOfAccessoriesCreation: PropTypes.func,
  selectedFabricOfAccessoriesCreation: PropTypes.PropTypes.arrayOf(PropTypes.string),
};

const renderAccessoryCreationQuestionsSelector = ({
  selectedAccessoryCreation, selectedCreationNumberOfPieces,
  selectedBagShape, selectedBagSize, selectedBagHandle, selectedBagClosing,
  selectAccessoryCreationsOptionsForBags, noHandleOption,
}) => (
  <ComponentOrEmptyDiv mustShow={selectedAccessoryCreation?.length > 0}>
    <WeListenToYouHeader />
    <Step1Creations
      selectedCreationNumberOfPieces={selectedCreationNumberOfPieces}
      selectedBagType={selectedAccessoryCreation}
      selectedBagShape={selectedBagShape}
      selectedBagSize={selectedBagSize}
      selectedBagHandle={selectedBagHandle}
      selectedBagClosing={selectedBagClosing}
      selectAccessoryCreationsOptionsForBags={selectAccessoryCreationsOptionsForBags}
      createRange={createRange}
      noHandleOption={noHandleOption}
    />
  </ComponentOrEmptyDiv>
);

renderAccessoryCreationQuestionsSelector.propTypes = {
  selectedAccessoryCreation: PropTypes.string,
  selectedBagShape: PropTypes.shape({}),
  selectedCreationNumberOfPieces: PropTypes.number,
  selectedBagSize: PropTypes.shape({}),
  selectedBagHandle: PropTypes.shape({}),
  selectedBagClosing: PropTypes.shape({}),
  noHandleOption: PropTypes.bool,
  selectAccessoryCreationsOptionsForBags: PropTypes.func,
};

const renderProblemsAnswersSelector = ({
  selectedProblems, getTreeLocations, selectLocations,
}) => (
  <ComponentOrEmptyDiv mustShow={!!selectedProblems && Object.keys(selectedProblems).length > 0}>
    {Object.keys(selectedProblems)
      .filter(selectedProblem => getTreeLocations(selectedProblem)?.locations?.length > 1)
      .filter(selectedProblem => selectedProblem !== 'talkRDV' && !selectedProblem.includes('_multiple'))
      .map(selectedProblem => (
        <Step1Where
          key={selectedProblem}
          selectedLocations={selectedProblems[selectedProblem]}
          selectLocations={slug => selectLocations(selectedProblem, slug)}
          treeLocations={getTreeLocations(selectedProblem)}
        />
      ))
    }
  </ComponentOrEmptyDiv>
);

renderProblemsAnswersSelector.propTypes = {
  selectedProblems: PropTypes.shape({}),
  getTreeLocations: PropTypes.func,
  selectLocations: PropTypes.func,
};

const renderProblemsAnswersMultiSelector = ({
  selectedProblems, setSelectValue, selectedCloth,
}) => (
  <ComponentOrEmptyDiv mustShow={!!selectedProblems && Object.keys(selectedProblems).length > 0}>
    {Object.keys(selectedProblems)
      .filter(selectedProblem => selectedProblem.includes('_multiple') && selectedProblems[selectedProblem]?.length > 0)
      .map((selectedProblem, index, filteredSelectedProblems) => (
        <Step1Multiple
          key={selectedProblem}
          slug={selectedProblem}
          createRange={createRange}
          setSelectValue={setSelectValue}
          selectedValue={selectedProblems[selectedProblem][0]}
          isLast={selectedCloth === 'acce' && index === filteredSelectedProblems.length - 1}
        />
      ))
    }
  </ComponentOrEmptyDiv>
);

renderProblemsAnswersMultiSelector.propTypes = {
  selectedProblems: PropTypes.shape({}),
  setSelectValue: PropTypes.func,
  selectedCloth: PropTypes.string,
};

const renderUpcyclingItemsSelector = ({
  selectedTypeOfWork, selectedPiece, selectedCloth, selectedFabric, upcyclingCategories,
  toggleUpcyclingCategory, upcyclingItems, toggleUpcyclingItem, setUpcyclingItemQuantity,
}) => {
  const mustShow = selectedTypeOfWork === 'transformation' && !selectedPiece
    && (!!selectedFabric || ['mari', 'pull', 'mail', 'ling', 'cost'].includes(selectedCloth))
    && !!treeUpcycling[selectedCloth];
  return (
    <ComponentOrEmptyDiv mustShow={mustShow}>
      <WeListenToYouHeader />
      <UpcyclingSelector
        tree={treeUpcycling[selectedCloth]}
        upcyclingCategories={upcyclingCategories}
        toggleUpcyclingCategory={toggleUpcyclingCategory}
        upcyclingItems={upcyclingItems}
        toggleUpcyclingItem={toggleUpcyclingItem}
        createRange={createRange}
        setUpcyclingItemQuantity={setUpcyclingItemQuantity}
      />
    </ComponentOrEmptyDiv>
  );
};

renderUpcyclingItemsSelector.propTypes = {
  selectedTypeOfWork: PropTypes.string,
  selectedPiece: PropTypes.string,
  selectedCloth: PropTypes.string,
  selectedFabric: PropTypes.string,
  upcyclingCategories: PropTypes.arrayOf(PropTypes.string),
  toggleUpcyclingCategory: PropTypes.func,
  upcyclingItems: PropTypes.arrayOf(PropTypes.shape({})),
  toggleUpcyclingItem: PropTypes.func,
  setUpcyclingItemQuantity: PropTypes.func,
};

const renderLiningSelector = ({
  isLiningDisplayed, selectLining, selectedLining,
}) => (
  <ComponentOrEmptyDiv mustShow={isLiningDisplayed}>
    <Step1Lining
      selectLining={selectLining}
      selectedLining={selectedLining}
    />
  </ComponentOrEmptyDiv>
);

renderLiningSelector.propTypes = {
  isLiningDisplayed: PropTypes.bool,
  selectLining: PropTypes.func,
  selectedLining: PropTypes.string,
};

const Step1Form = (props) => {
  const { setRef, context: { isMobile }, selectedCategory, selectCategory } = props;

  return (
    <React.Fragment>
      <Container marginBottom={selectedCategory === 'RDV3Clicks'}>
        <CategorySelector category={selectedCategory} selectCategory={selectCategory} />

        {renderRelative(setRef, 'Step1Top')}

        {renderRDV3Clicks(props)}

        {!!selectedCategory && selectedCategory !== 'RDV3Clicks'
          ? (
            <div>
              {renderRelative(setRef, 'Step1TypeOfWork')}

              {renderTypeOfWorkSelector(props)}

              {renderRelative(setRef, 'Step1Piece', !isMobile)}

              {renderPieceSelector(props)}
              {renderAccessoryCreationSelector(props)}

              {renderRelative(setRef, 'Step1Fabrics', !isMobile)}

              {renderFabricSelector(props)}
              {renderAccessoryCreationFabricsSelector(props)}

              {renderRelative(setRef, 'Step1AdditionalInformation', !isMobile)}

              {renderSuitSelector(props)}
              {renderAlterationProblemsSelector(props)}
              {renderProblemsAnswersSelector(props)}
              {renderProblemsAnswersMultiSelector(props)}
              {renderLiningSelector(props)}

              {renderDecoQuestionsContainer(props)}
              {renderEmbroideriesSelector(props)}
              {renderUpcyclingInspirationsContainer(props)}
              {renderAccessoryCreationQuestionsSelector(props)}
              {renderUpcyclingItemsSelector(props)}
            </div>
          ) : <div />
        }
      </Container>
    </React.Fragment>
  );
};

Step1Form.propTypes = {
  selectedCategory: PropTypes.string,
  setRef: PropTypes.func.isRequired,
  selectCategory: PropTypes.func.isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

Step1Form.defaultProps = {
  selectedCategory: undefined,
};

export default withAppContext(Step1Form);
