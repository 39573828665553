import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import fabrics from './fabrics';
import withAppContext from '../../withAppContext';
import withOrderContext from '../../withOrderContext';
import routesMap from '../../Routes';

import { Title as TitleOrder, Subtitle as SubtitleOrder } from './orderStyledComponents';
import OutInSideClick from '../home/v2/OutInSideClick';
import { colors, mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';
import { pushToLayer } from '../../services/googleTagManager';
import Dots from '../home/v3/Dots';

import topSuit from '../../assets/order/rdv3clics/top-suit.png';
import bottomSuit from '../../assets/order/rdv3clics/bottom-suit.png';

const Container = styled.div`
  background-color: ${colors.white};
  color: ${colors.navy};
  font-family: Roboto;
  width: 994px;
  position: relative;
  left: 0;
  right: 0;
  top: -700px;
  margin: 0 auto;
  padding: 88px 142px 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100% - 10px);
    padding: 88px 20px 60px 20px;
    top: -850px;
  }
`;

const Title = styled(TitleOrder)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 28px;
  }
`;

const Subtitle = styled(SubtitleOrder)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

const Text = styled.p`
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${colors.navy};
  margin: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 15px;
    font-size: 12px;
    padding: 0px 10px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 70px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
    ${props => props.mobileCenter && 'justify-content: center; margin-bottom: 40px;'}
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.isActive && `background-color: ${colors.navy}; color: ${colors.white};`}
`;

const KnowHowCard = styled(FlexContainer)`
  width: 200px;
  height: 40px;
  line-height: 1;
  margin: 10px 0px;
  padding: 0 5px;
  text-align: center;
  width: 200px;
  ${props => !props.unavailable && `
    border: 0.75px solid ${colors.blueGrey};
    border-radius: 3px;
    box-sizing: border-box;
    margin: 5px 0px;
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const FabricCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
  margin-bottom: 51px;
  box-shadow: 0px 1px 6px ${colors.blueGrey};
  cursor: pointer;
  ${({ imageId }) => imageId && `
    &:hover #${imageId}{
      opacity: 0.7;
      transition: visibility .2s;
    }
  `}
  ${({ filterId }) => filterId && `
    &:hover #${filterId}{
      visibility: visible;
      transition: visibility .2s;
    }
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
    width: calc(50vw - 50px);
  }
`;

const FabricImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: auto;
    width: 100%;
  }
`;

const FabricImg = styled.img`
  height: 100%;
  width: 100%;
  ${({ showFilter }) => showFilter && 'opacity: 0.7;'}
`;

const FabricName = styled(FlexContainer)`
  height: 39px;
  font-family: Libre Baskerville;
  @media (max-width: ${mobileThresholdPixels}) {
    height: 25px;
  }
`;

const Button = styled(ButtonV3)`
  width: 245px;
  margin-top: -41px;
  ${props => !props.isAvailable && `
    background-color: ${colors.lightGrey3};
    color: ${colors.white};
    pointer-events: none;
    cursor: unset;
    border-color: ${colors.lightGrey3};
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    min-width: unset;
    height: 45px;
    width: 200.5px;
    margin-top: 60px;
  }
`;

const SuitAlterationsRow = styled.div`
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  line-height: 26px;
  margin-top: 40px;
  width: 100%;
  ${props => props.largeButton && `
    padding-bottom: 110px;
    padding-top: 20px;
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0;
    margin-top: 0;
    width: 274px;
    ${props => props.mobileCenter && 'justify-content: center;'}
    ${props => props.firstChild && 'margin-top: 40px;'}
    ${props => props.largeButton && `
      padding-bottom: 60px;
      padding-top: 0;
  `}
  }
`;

const SuitAlterationsWrapperImg = styled.div`
  height: 120px;
  width: 120px;
  margin-right: 60px;
  ${props => props.bottomSuit && 'height: 163px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    display: none;
  }
`;

const SuitAlterationsImg = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const SuitAlterationsWrapperButton = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const SuitAlterationsButton = styled(FlexContainer)`
  cursor: pointer;
  height: 40px;
  line-height: 1;
  margin: 10px 0px;
  padding: 0 5px;
  text-align: center;
  width: 200px;
  ${props => props.largeButton && 'width: 350px;'}
  ${props => !props.unavailable && `
    border: 0.75px solid ${colors.blueGrey};
    border-radius: 3px;
    box-sizing: border-box;
    margin: 5px 0px;
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Filter = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 224px;
  padding: 0px 22px;
  color: ${colors.white};
  box-sizing: border-box;
  text-align: center;
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 12px;
    width: calc(50vw - 50px);
    ${({ isVisibleMobile }) => isVisibleMobile && `
      visibility: visible;
    `};
  }
`;

const FilterTitle = styled.div`
  font-family: Libre Baskerville;
  font-size: 18px;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 5px;
  }
`;

const FilterText = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 9px;
    line-height: 11px;
  }
`;

const getKnowHowList = () => (
  [
    { label: 'Ce sont mes rideaux', isActive: false, slug: 'curtain' },
    { label: 'C’est un costume', isActive: false, slug: 'suit' },
    { label: 'C’est de la broderie', isActive: false, slug: 'embroidery' },
    { label: 'C’est ma robe de mariée', isActive: false, slug: 'mari' },
    { label: 'C’est de la lingerie', isActive: false, slug: 'lingerie' },
    { label: 'C’est une création d’accessoires', isActive: false, slug: 'creation' },
    { label: 'C’est un vêtement technique', isActive: false, slug: 'bath' },
    { label: 'C’est un manteau/veste', isActive: false, slug: 'coat' },
    { label: 'Transformer le design d’un vêtement', isActive: false, slug: 'upcycling' },
    { label: 'Rien de spécial', isActive: false, slug: '' },
  ]
);

const getAlterationsSuitList = () => (
  [
    {
      label: 'Le col',
      isActive: false,
      slug: 'collar',
    },
    {
      label: 'Les manches',
      isActive: false,
      slug: 'sleeves',
    },
    {
      label: 'La ceinture',
      isActive: false,
      slug: 'belt',
    },
    {
      label: 'Les jambes',
      isActive: false,
      slug: 'legs',
    },
    {
      label: 'Rien de compliqué (ourlet, réparation …)',
      isActive: false,
      slug: 'nothingComplex',
    },
  ]
);

const getFabricsList = () => (
  fabrics
    .filter(fabric => fabric.name !== 'Tailleur')
    .map(fabric => ({ ...fabric, isActive: false }))
);

const renderHeader = () => (
  <>
    <Title marginBottom={40}>Quelques détails pour vous envoyer le meilleur couturier</Title>
    <Text>Certaines matières nécessitent un savoir-faire et des outils spécifiques.</Text>
    <Text>Si besoin, nous vous enverrons un spécialiste. Pas d’inquiétude, le prix reste le même.</Text>
  </>
);

function getInitialState() {
  return {
    alterationsActivitiesSuit: getAlterationsSuitList(),
    fabricsActivities: getFabricsList(),
    knowHowActivities: getKnowHowList(),
    isSelectedSuitCloth: false,
    numberOfActiveKnowhow: 0,
    isFurSelected: false,
  };
}

class Step1RDV3Clicks extends React.Component {
  constructor(props) {
    super(props);
    const { orderContext: { rdv3ClicksOrder } } = this.props;

    if (rdv3ClicksOrder && rdv3ClicksOrder.knowHow && rdv3ClicksOrder.knowHow.length > 0) {
      const rdv3ClicksKnowHowSlugs = rdv3ClicksOrder.knowHow.map(knowHow => knowHow.slug);
      const knowHowActivities = getKnowHowList()
        .map(knowHow => ({ ...knowHow, isActive: rdv3ClicksKnowHowSlugs?.includes(knowHow.slug) }));

      const fabricsActivities = getFabricsList()
        .map(fabric => ({ ...fabric, isActive: rdv3ClicksOrder?.fabrics?.includes(fabric.slug) }));

      const rdv3ClicksSuitAlterationsSlugs = rdv3ClicksOrder.suitAlterations?.map(alteration => alteration.slug);
      const alterationsActivitiesSuit = getAlterationsSuitList()
        .map(alteration => ({ ...alteration, isActive: rdv3ClicksSuitAlterationsSlugs?.includes(alteration.slug) }));

      this.state = {
        alterationsActivitiesSuit: [...alterationsActivitiesSuit],
        fabricsActivities: [...fabricsActivities],
        knowHowActivities: [...knowHowActivities],
        isSelectedSuitCloth: this.getIsSelectedSuitCloth(knowHowActivities),
        numberOfActiveKnowhow: this.getNumberOfActiveKnowhow(knowHowActivities),
        isFurSelected: rdv3ClicksOrder.isFurSelected,
      };
    } else {
      this.state = {
        ...getInitialState(),
      };
    }

    this.toggleCardActivity = this.toggleCardActivity.bind(this);
    this.goNext = this.goNext.bind(this);
  }

  getIsSelectedSuitCloth = knowHowActivities => knowHowActivities.find(item => item.slug === 'suit').isActive;

  getNumberOfActiveKnowhow = knowHowActivities => knowHowActivities.filter(item => item.isActive === true).length;

  goNext() {
    const { knowHowActivities, fabricsActivities, alterationsActivitiesSuit } = this.state;
    const { orderContext: { setRDV3ClicksOrder } } = this.props;

    const selectedKnowHow = knowHowActivities
      .filter(knowHow => knowHow.isActive)
      .map(({ label, slug }) => ({ label, slug }));

    const selectedFabrics = fabricsActivities.filter(fabric => fabric.isActive).map(fabric => fabric.slug);

    const selectedAlterationsSuit = alterationsActivitiesSuit
      .filter(alteration => alteration.isActive)
      .map(({ label, slug }) => ({ label, slug }));

    setRDV3ClicksOrder(selectedKnowHow, selectedFabrics, selectedAlterationsSuit);
    navigate(routesMap.Step2.url);
  }

  toggleCardActivity(index, listName) {
    const { knowHowActivities } = this.state;
    const list = this.state[listName];

    if (listName === 'fabricsActivities' && list[index].slug === 'fur') {
      this.setState({ isFurSelected: true });
    } else {
      list[index].isActive = !list[index].isActive;
      this.setState({
        [listName]: [...list],
      }, () => {
        this.setState({
          isSelectedSuitCloth: this.getIsSelectedSuitCloth(knowHowActivities),
          numberOfActiveKnowhow: this.getNumberOfActiveKnowhow(knowHowActivities),
        });
      });
    }
    if (listName === 'knowHowActivities') {
      pushToLayer({
        event: 'Order Funnel - Lite - Know-How Chosen',
      });
    } else {
      pushToLayer({
        event: 'Order Funnel - Lite - Fabric Chosen',
        fabric: this.state.fabricsActivities[index].slug,
      });
    }
  }

  toggleSuit = (slug, index) => {
    let newAlterationsActivitiesSuit = [...this.state.alterationsActivitiesSuit];
    const nothingComplexIndex = newAlterationsActivitiesSuit.findIndex(item => item.slug === 'nothingComplex');

    if (slug === 'nothingComplex' && newAlterationsActivitiesSuit[nothingComplexIndex].isActive) {
      newAlterationsActivitiesSuit[nothingComplexIndex].isActive = false;
    } else if (slug === 'nothingComplex' && !newAlterationsActivitiesSuit[nothingComplexIndex].isActive) {
      newAlterationsActivitiesSuit = getAlterationsSuitList();
      newAlterationsActivitiesSuit[nothingComplexIndex].isActive = true;
    } else {
      newAlterationsActivitiesSuit[index].isActive = !newAlterationsActivitiesSuit[index].isActive;
      newAlterationsActivitiesSuit[nothingComplexIndex].isActive = false;
    }

    this.setState({
      alterationsActivitiesSuit: [...newAlterationsActivitiesSuit],
    });
  }

  renderKnowHowActivities = knowHowActivities => (
    <>
      <Subtitle marginTop>{"Besoin d'un savoir-faire spécifique ?"}</Subtitle>
      <Dots justifyContent="center" noMargin />
      <CardsContainer mobileCenter>
        {knowHowActivities.map((knowHow, index) => (
          <KnowHowCard
            key={knowHow.label}
            onClick={() => this.toggleCardActivity(index, 'knowHowActivities')}
            isActive={knowHow.isActive}
          >
            {knowHow.label}
          </KnowHowCard>
        ))}
        <KnowHowCard unavailable />
      </CardsContainer>
    </>
  );

  renderFabricsActivities = (fabricsActivities, isMobile, isFurSelected) => (
    <>
      <Subtitle>{'Une matière particulière ?'}</Subtitle>
      <Dots justifyContent="center" noMargin />
      <CardsContainer>
        {fabricsActivities.map(({ slug, name, isActive, image, filterContent }, index) => (
          <FabricCard
            key={name}
            onClick={() => this.toggleCardActivity(index, 'fabricsActivities')}
            imageId={filterContent?.showOnHover && !isMobile && `image${slug}`}
            filterId={filterContent?.showOnHover && !isMobile && `filter${slug}`}
          >
            <FabricImgContainer>
              <FabricImg id={`image${slug}`} src={image} alt={name} showFilter={filterContent && ((slug === 'fur' && isFurSelected) || isActive)} />
              {filterContent && (
                <Filter
                  id={`filter${slug}`}
                  isVisible={(slug === 'fur' && isFurSelected) || isActive}
                  isVisibleMobile={filterContent.alwaysShowOnMobile}
                >
                  <FilterTitle>{filterContent.title}</FilterTitle>
                  <FilterText>{filterContent.text}</FilterText>
                </Filter>
              )}
            </FabricImgContainer>
            <FabricName isActive={isActive}>
              {(isMobile && slug === 'other') ? 'Autre' : name}
            </FabricName>
          </FabricCard>
        ))}
      </CardsContainer>
    </>
  );

  renderAlterationsActivitiesSuit = alterationsActivitiesSuit => (
    <>
      <Subtitle>{'À quel endroit seront les retouches ?'}</Subtitle>
      <Dots justifyContent="center" noMargin />
      <SuitAlterationsRow mobileCenter firstChild>
        <SuitAlterationsWrapperImg>
          <SuitAlterationsImg src={topSuit} alt="Retouche" topSuit />
        </SuitAlterationsWrapperImg>
        <SuitAlterationsWrapperButton>
          {alterationsActivitiesSuit.slice(0, 2).map(({ label, isActive, slug }, index) => (
            <SuitAlterationsButton
              key={label}
              onClick={() => this.toggleSuit(slug, index)}
              isActive={isActive}
            >{label}</SuitAlterationsButton>
          ))}
        </SuitAlterationsWrapperButton>
      </SuitAlterationsRow>
      <SuitAlterationsRow mobileCenter>
        <SuitAlterationsWrapperImg bottomSuit>
          <SuitAlterationsImg src={bottomSuit} alt="Retouche" />
        </SuitAlterationsWrapperImg>
        <SuitAlterationsWrapperButton>
          {alterationsActivitiesSuit.slice(2, 4).map(({ label, isActive, slug }, index) => (
            <SuitAlterationsButton
              key={label}
              onClick={() => this.toggleSuit(slug, `${index + 2}`)}
              isActive={isActive}
            >{label}</SuitAlterationsButton>
          ))}
        </SuitAlterationsWrapperButton>
      </SuitAlterationsRow>
      <SuitAlterationsRow mobileCenter largeButton>
        {alterationsActivitiesSuit
          .filter((item, index) => alterationsActivitiesSuit.length - 1 === index)
          .map(({ label, isActive, slug }, index) => (
            <SuitAlterationsButton
              largeButton
              key={label}
              onClick={() => this.toggleSuit(slug, index)}
              isActive={isActive}
            >{label}</SuitAlterationsButton>
          ))}
      </SuitAlterationsRow>
    </>
  );

  render() {
    const { context: { isMobile }, unsetCategory } = this.props;
    const {
      knowHowActivities, fabricsActivities, alterationsActivitiesSuit, isSelectedSuitCloth, numberOfActiveKnowhow, isFurSelected,
    } = this.state;
    const isDone = knowHowActivities.reduce((acc, { isActive }) => acc || isActive, false);

    return (
      <OutInSideClick handleClickOutside={unsetCategory}>
        <Container>
          {renderHeader()}

          {this.renderKnowHowActivities(knowHowActivities)}

          {isSelectedSuitCloth && numberOfActiveKnowhow === 1 &&
            this.renderAlterationsActivitiesSuit(alterationsActivitiesSuit)
          }

          {isSelectedSuitCloth && numberOfActiveKnowhow > 1 &&
            <>
              {this.renderAlterationsActivitiesSuit(alterationsActivitiesSuit)}
              {this.renderFabricsActivities(fabricsActivities, isMobile, isFurSelected)}
            </>
          }

          {!isSelectedSuitCloth && numberOfActiveKnowhow >= 1 &&
            this.renderFabricsActivities(fabricsActivities, isMobile, isFurSelected)
          }

          <Button navy onClick={this.goNext} isAvailable={isDone}>Prendre RDV</Button>
        </Container>
      </OutInSideClick>
    );
  }
}

Step1RDV3Clicks.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  orderContext: PropTypes.shape({
    rdv3ClicksOrder: PropTypes.shape(),
    setRDV3ClicksOrder: PropTypes.func,
  }).isRequired,
  unsetCategory: PropTypes.func.isRequired,
};

export default withAppContext(withOrderContext(Step1RDV3Clicks));
