import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Row as RowOrder,
  Content,
  CardsContainer as CardsContainerOrder,
  mobileThresholdPixels,
  Subtitle,
  colors,
} from './orderStyledComponents';
import CardSelector from './CardSelector';

import Dots from '../home/v3/Dots';

const Row = styled(RowOrder)`
  margin-top: 0px;
`;

const Row2 = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CardsContainer = styled(CardsContainerOrder)`
  margin-top: 51px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: ${props => props.littleCard ? '0px 10px 53px 10px' : 'auto'};
  width: ${props => props.littleCard ? '224px' : '345px'};
  color: ${props => props.active ? colors.white : colors.navy};
  background-color:${props => props.active ? colors.navy : colors.white};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0 0 15px 0;
    width: calc(50vw - 30px);
  }
`;

const BackgroundCard = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 170px;
  line-height: 20px;
  padding: 20px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px;
  }
`;

const TextContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${props => props.littleCard ? '39px' : '50px'};
  justify-content: center;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 25px;
  }
`;

const TextButton = styled.section`
  font-family: Libre Baskerville;
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 12px;
  }
`;

const Step1FabricsListOfAccessoriesCreation = ({
  fabricsListOfAccessoriesCreation,
  selectFabricOfAccessoriesCreation,
  selectedFabricOfAccessoriesCreation,
}) => (
  <Row smallMarginTop>
    <Content marginTop={20}>
      <Subtitle>Avec quel tissu ?</Subtitle>
      <Dots justifyContent="center" noMargin />
      <Row2>
        <CardsContainer center>
          {fabricsListOfAccessoriesCreation.map(fabric => (
            <CardSelector
              key={fabric.slug}
              slug={fabric.slug}
              image={fabric.image}
              label={fabric.name}
              alt={fabric.name}
              onClick={() => selectFabricOfAccessoriesCreation(fabric.slug)}
              littleCard
              active={selectedFabricOfAccessoriesCreation.includes(fabric.slug)}
            />),
          )}
          <ButtonContainer
            slug="tissu"
            onClick={() => selectFabricOfAccessoriesCreation('tissu')}
            littleCard
            active={selectedFabricOfAccessoriesCreation.includes('tissu')}
          >
            <BackgroundCard littleCard>
              Réutilisez vos chutes de tissus ou anciens vêtements
            </BackgroundCard>
            <TextContainer littleCard>
              <TextButton>
                J’ai du tissu
              </TextButton>
            </TextContainer>
          </ButtonContainer>
        </CardsContainer>
      </Row2>
    </Content>
  </Row>
);

Step1FabricsListOfAccessoriesCreation.propTypes = {
  fabricsListOfAccessoriesCreation: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.string,
  })),
  selectFabricOfAccessoriesCreation: PropTypes.func.isRequired,
  selectedFabricOfAccessoriesCreation: PropTypes.PropTypes.arrayOf(PropTypes.string),
};

Step1FabricsListOfAccessoriesCreation.defaultProps = {
  fabricsListOfAccessoriesCreation: [],
  selectFabricOfAccessoriesCreation: () => {},
  selectedFabricOfAccessoriesCreation: [],
};

export default Step1FabricsListOfAccessoriesCreation;
