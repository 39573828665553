import treeUpcycling, { treeUpcyclingInverse } from './order/treeUpcycling';

export const scrollToStep = (ref, withTimeout = false) => {
  const scrollProps = {
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  };
  if (withTimeout) setTimeout(() => ref.scrollIntoView(scrollProps), 200);
  else ref.scrollIntoView(scrollProps);
};

export const shouldDisplayLining = (selectedProblems) => {
  if (!selectedProblems) return false;

  const selectedProblemsToCheckForLining = Object.keys(selectedProblems)
    .filter(problem => problem !== 'talkRDV')
    .map(problem => selectedProblems[problem]);

  return selectedProblemsToCheckForLining.length > 0
    ? selectedProblemsToCheckForLining.reduce((acc, selectedProblem) => acc &&
      selectedProblem.length > 0 &&
      (typeof selectedProblem[0] !== 'object' || selectedProblem[0].value !== -1)
    , true)
    : false;
};

const isSelectingEmbroideryDone = (state) => {
  const { embroideries: embroideriesState, isDrawingEasy, activeEmbroideryFontSize } = state;
  if (embroideriesState[2].isActive && !embroideriesState[1].isActive) {
    return isDrawingEasy !== undefined;
  } else if (embroideriesState[1].isActive && !embroideriesState[2].isActive) {
    return activeEmbroideryFontSize !== -1;
  } else if (embroideriesState[2].isActive && embroideriesState[1].isActive) {
    return isDrawingEasy !== undefined && activeEmbroideryFontSize !== -1;
  }
  return embroideriesState[0].isActive || embroideriesState[3].isActive;
};

const isSelectingClothesDone = (state) => {
  const {
    selectedCloth, selectedLining, selectedProblems, suitPieces, suitProblems, suitPieceLinings,
  } = state;
  if (selectedCloth === 'cost') {
    if (suitPieces.length === 0) return false;
    for (let i = 0; i < suitPieces.length; i += 1) {
      const suitProblemsForCurrentPiece = suitProblems.filter(suitProblem => suitProblem.includes(`${suitPieces[i]}_`));
      if (suitProblemsForCurrentPiece.length === 0
        || !(suitPieceLinings[suitPieces[i]]
        || (suitProblemsForCurrentPiece.length === 1 && suitProblemsForCurrentPiece[0].includes('talkRDV')))) return false;
    }
    return true;
  }
  if (selectedCloth === 'acce') {
    if (Object.keys(selectedProblems).length === 0) return false;
    if (selectedProblems.damaged) return selectedProblems.damaged.length > 0;
    return true;
  }
  return (Object.keys(selectedProblems).length === 1 && Object.keys(selectedProblems)[0] === 'talkRDV')
    || selectedLining !== '';
};

const isSelectingCurtainsDone = (state) => {
  const { curtainAlterations: alterations, activeCurtainAdjustment } = state;

  if (alterations[0].isActive) {
    return activeCurtainAdjustment !== -1;
  }
  for (let i = 1; i < 3; i += 1) {
    if (alterations[i].isActive) return true;
  }
  return false;
};

const isSelectingCushionsDone = (state) => {
  const { isCushionAlterationSelected } = state;
  return isCushionAlterationSelected;
};

const isSelectingLinensDone = (state) => {
  const { linensProblems: problems, linensAlterations: alterations, linensWhere: where } = state;

  if (problems[0].isActive && problems[1].isActive) {
    return alterations.reduce((acc, alteration) => acc || alteration.isActive, false)
      && where.reduce((acc, location) => acc || location.isActive, false);
  } else if (problems[0].isActive) {
    return alterations.reduce((acc, alteration) => acc || alteration.isActive, false);
  } else if (problems[1].isActive) {
    return where.reduce((acc, location) => acc || location.isActive, false);
  }
  return false;
};

const isSelectingUpcyclingDone = (state) => {
  const { selectedPiece, selectedCloth, selectedFabric, upcyclingCategories, upcyclingItems } = state;
  let isDone = !!selectedPiece || (!!selectedCloth && (!!selectedFabric || ['mari', 'pull', 'mail', 'ling', 'cost'].includes(selectedCloth)));
  if (!isDone) return false;
  const piece = selectedPiece || selectedCloth;
  isDone = !treeUpcycling[piece];
  if (isDone) return true;
  if (upcyclingCategories.length === 0 || upcyclingItems.length === 0) return false;
  const hasAtLeastOneItemPerCategorySelected = upcyclingCategories.reduce((acc, upcyclingCategory) => {
    if (acc) {
      for (let i = 0; i < upcyclingItems.length; i += 1) {
        if (treeUpcyclingInverse[upcyclingItems[i].id].categories.includes(upcyclingCategory)) return true;
      }
    }
    return false;
  }, true);
  return hasAtLeastOneItemPerCategorySelected;
};

export const isSelectingDone = (state) => {
  const {
    selectedCategory, selectedPiece, selectedTypeOfWork,
    selectedBagClosing,
  } = state;

  switch (selectedTypeOfWork) {
    case 'alteration':
      if (selectedCategory === 'clothes') return isSelectingClothesDone(state);
      if (selectedCategory === 'deco' && selectedPiece === 'ride') return isSelectingCurtainsDone(state);
      if (selectedCategory === 'deco' && selectedPiece === 'cous') return isSelectingCushionsDone(state);
      if (selectedCategory === 'deco' && selectedPiece === 'mais') return isSelectingLinensDone(state);
      break;
    case 'embroidery':
      return isSelectingEmbroideryDone(state);
    case 'transformation':
      return isSelectingUpcyclingDone(state);
    case 'creation':
      return Object.keys(selectedBagClosing).length > 0;
    default:
      return false;
  }

  return false;
};
