import React from 'react';
import styled from 'styled-components';

import { mobileThresholdPixels } from '../styledComponents';
import { Row, Subtitle } from './orderStyledComponents';
import Dots from '../home/v3/Dots';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  margin: 0px 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 10px;
  }
`;

const WeListenToYouHeader = () => (
  <Row mobileMarginTop={20}>
    <Column>
      <Subtitle>On vous écoute</Subtitle>
      <Dots justifyContent="center" />
    </Column>
  </Row>
);


export default WeListenToYouHeader;
