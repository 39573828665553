import hautOther1 from '../../assets/order/transformations/haut.other.1.jpg';
import hautOther2 from '../../assets/order/transformations/haut.other.2.jpg';
import hautOther3 from '../../assets/order/transformations/haut.other.3.jpg';
import hautSilk1 from '../../assets/order/transformations/haut.silk.1.jpg';
import hautSilk2 from '../../assets/order/transformations/haut.silk.2.jpg';
import hautSilk3 from '../../assets/order/transformations/haut.silk.3.jpg';
import mantSuit1 from '../../assets/order/transformations/mant.suit.1.jpg';
import mantSuit2 from '../../assets/order/transformations/mant.suit.2.jpg';
import mantSuit3 from '../../assets/order/transformations/mant.suit.3.jpg';
import pullOther1 from '../../assets/order/transformations/pull.other.1.jpg';
import pullOther2 from '../../assets/order/transformations/pull.other.2.jpg';
import pullOther3 from '../../assets/order/transformations/pull.other.3.jpg';
import pullStitch1 from '../../assets/order/transformations/pull.stitch.1.jpg';
import pullStitch2 from '../../assets/order/transformations/pull.stitch.2.jpg';
import pullStitch3 from '../../assets/order/transformations/pull.stitch.3.jpg';
import robeOther1 from '../../assets/order/transformations/robe.other.1.jpg';
import robeOther2 from '../../assets/order/transformations/robe.other.2.jpg';
import robeOther3 from '../../assets/order/transformations/robe.other.3.jpg';
import robeSilk1 from '../../assets/order/transformations/robe.silk.1.jpg';
import robeSilk2 from '../../assets/order/transformations/robe.silk.2.jpg';
import robeSilk3 from '../../assets/order/transformations/robe.silk.3.jpg';
import robeSuit1 from '../../assets/order/transformations/robe.suit.1.jpg';
import robeSuit2 from '../../assets/order/transformations/robe.suit.2.jpg';
import robeSuit3 from '../../assets/order/transformations/robe.suit.3.jpg';
import jupeJeans1 from '../../assets/order/transformations/jupe.jeans.1.jpg';
import jupeJeans2 from '../../assets/order/transformations/jupe.jeans.2.jpg';
import jupeJeans3 from '../../assets/order/transformations/jupe.jeans.3.jpg';

export const inspirations = {
  mant: {
    suit: [
      {
        image: mantSuit1,
        content: {
          title: 'Réutiliser la veste tailleur homme',
          text: 'La veste tailleur homme est un incontournable, cela dit, il faut savoir se l’approprier. Cette transformation garantit un look des années 90 qu’on adore !', // eslint-disable-line max-len
          estimatedPrice: '170 €',
        },
      },
      {
        image: mantSuit2,
        content: {
          title: 'Une ceinture originale',
          text: 'Dans le cas où votre tailleur est abimé, il y a la possibilité d’en faire une ceinture. Assortie avec un pantalon de tailleur c’est élégant et unique ;)', // eslint-disable-line max-len
          estimatedPrice: '80 €',
        },
      },
      {
        image: mantSuit3,
        content: {
          title: 'Une boutonnière dans le dos',
          text: 'Purement esthétique, donnez un petit plus à votre veste de costume.',
          estimatedPrice: '80 €',
        },
      },
    ],
  },
  robe: {
    other: [
      {
        image: robeOther1,
        content: {
          title: 'Le détail qui change tout',
          text: 'Avec une ouverture au col, une robe simple devient une pièce minimaliste et raffinée. On adore !',
          estimatedPrice: '25 €',
        },
      },
      {
        image: robeOther2,
        content: {
          title: 'Un coup de fraicheur !',
          text: 'On fait un joli décolleté plongeant et on rajoute une boutonnière à sa robe bohème pour s’en servir aussi comme gilet ;)', // eslint-disable-line max-len
          estimatedPrice: '40 €',
        },
      },
      {
        image: robeOther3,
        content: {
          title: 'Une autre paire de manche',
          text: 'Découper des manches longues pour en faire des manches 3/4 en laissant le poignet, c’est joli comme tout !', // eslint-disable-line max-len
          estimatedPrice: '40 €',
        },
      },
    ],
    silk: [
      {
        image: robeSilk1,
        content: {
          title: '2 en 1',
          text: 'Une robe en soie devient un ensemble de pyjama !',
          estimatedPrice: '110 €',
        },
      },
      {
        image: robeSilk2,
        content: {
          title: 'De l’élégance',
          text: 'Ouvrir votre robe aux côtes, c’est une bonne idée pour une robe de soirée :)',
          estimatedPrice: '90 €',
        },
      },
      {
        image: robeSilk3,
        content: {
          title: 'Personnaliser les manches',
          text: 'En rajoutant un ruban vous pouvez donner un effet bouffant à la manche et peaufiner votre tenue.',
          estimatedPrice: '30 €',
        },
      },
    ],
    suit: [
      {
        image: robeSuit1,
        content: {
          title: 'Le cache coeur',
          text: 'Les robes tailleur peuvent parfois paraître trop strictes et impersonnelles alors pourquoi ne pas faire un cache coeur ?', // eslint-disable-line max-len
          estimatedPrice: '50 €',
        },
      },
      {
        image: robeSuit2,
        content: {
          title: 'Ouverture perlée',
          text: 'Un décolleté simple peut devenir trés chic avec une petite touche de folie ;)', // eslint-disable-line max-len
          estimatedPrice: '40 €',
        },
      },
      {
        image: robeSuit3,
        content: {
          title: 'Destructuration',
          text: 'On revisite entièrement la robe tailleur... Et hop, une manche en moins !', // eslint-disable-line max-len
          estimatedPrice: '90 €',
        },
      },
    ],
  },
  haut: {
    other: [
      {
        image: hautOther1,
        content: {
          title: 'Un peu de changement',
          text: 'Réinventer le col de son haut en y rajoutant des perles.',
          estimatedPrice: 'entre 80 et 90€',
        },
      },
      {
        image: hautOther2,
        content: {
          title: 'Le décolleté',
          text: 'On crée un décolleté à son haut à col simple.',
          estimatedPrice: 'entre 50 et 60€',
        },
      },
      {
        image: hautOther3,
        content: {
          title: 'Les manches',
          text: 'On coupe les manches longues et on en fait des manches légèrement bouffantes.',
          estimatedPrice: 'entre 70 et 80€',
        },
      },
    ],
    silk: [
      {
        image: hautSilk1,
        content: {
          title: 'Un col foulard',
          text: 'Avec un haut en soie pourquoi ne pas compléter un autre haut pour faire un effet foulard intégré ?',
          estimatedPrice: '70 €',
        },
      },
      {
        image: hautSilk2,
        content: {
          title: 'Personnaliser les manches',
          text: 'En rajoutant un ruban vous pouvez donner un effet bouffant à la manche et peaufiner votre tenue.',
          estimatedPrice: '30 €',
        },
      },
      {
        image: hautSilk3,
        content: {
          title: 'Un foulard',
          text: 'On change complètement du vieux haut en soie et on le réinvente... en foulard !',
          estimatedPrice: '20 €',
        },
      },
    ],
  },
  jupe: {
    jeans: [
      {
        image: jupeJeans1,
        content: {
          title: 'Recoller les morceaux',
          text: 'En prenant plusieurs pièces de jean il est possible de recréer une jupe.',
          estimatedPrice: '75 €',
        },
      },
      {
        image: jupeJeans2,
        content: {
          title: 'Ouverture lacée',
          text: 'Rajouter des lacets sur le côté d’une jupe pour la personnaliser !',
          estimatedPrice: '55 €',
        },
      },
      {
        image: jupeJeans3,
        content: {
          title: 'Une pincée d’élégance',
          text: 'On destructure la coupe de la jupe et on y ajoute des boutons (ou autre) pour l’originalité ',
          estimatedPrice: '50 €',
        },
      },
    ],
  },
  pull: {
    other: [
      {
        image: pullOther1,
        content: {
          title: 'Personnaliser un sweat',
          text: 'Transformer un basique en un vêtement unique en le personnalisant : Comme ce sweat shirt où on a rajouté des pompoms (40 exactement).', // eslint-disable-line max-len
          estimatedPrice: '40 €',
        },
      },
      {
        image: pullOther2,
        content: {
          title: 'On change les manches !',
          text: 'Le sweat revisité : On fait des bandes de tulles sur les manches pour plus de légèreté... D’ailleurs on pourrait aussi faire les manches entières !', // eslint-disable-line max-len
          estimatedPrice: '40 €',
        },
      },
      {
        image: pullOther3,
        content: {
          title: 'Les épaules à l’air',
          text: 'Découper les épaules lorsqu’elles sont abîmées ou pour donner du style à votre vêtement !',
          estimatedPrice: '40 €',
        },
      },
    ],
    stitch: [
      {
        image: pullStitch1,
        content: {
          title: 'Découpe d’un pull',
          text: 'Revisiter un vieux pull en le découpant ! Avec un vêtement dessous ça fait un effet layering qu’on apprécie beaucoup :)', // eslint-disable-line max-len
          estimatedPrice: '60 €',
        },
      },
      {
        image: pullStitch2,
        content: {
          title: 'Un trou ? Pas de soucis ;)',
          text: 'Il est possible de rajouter de la matière comme de la dentelle pour sublimer votre pull !', // eslint-disable-line max-len
          estimatedPrice: '25 €',
        },
      },
      {
        image: pullStitch3,
        content: {
          title: 'Transformer son pull en jupe',
          text: 'C’est plus qu’un coup de frais que vous redonnez à votre pull.', // eslint-disable-line max-len
          estimatedPrice: '110 €',
        },
      },
    ],
  },
};
