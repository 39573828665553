const treeUpcycling = {
  mant: {
    name: 'Manteau',
    categories: {
      slug: 'partOfPiece',
      question: 'Quelle partie du manteau souhaitez-vous transformer ?',
      answers: [
        {
          slug: 'sleeves',
          label: 'Manches / Épaules',
          question: 'Pour les manches vous voulez :',
          answers: [
            { slug: 'sleeveOrnament', label: 'Ajouter des ornements', id: '1.mant-upc-1' },
            { slug: 'sleeveCreation', label: 'Les créer', id: '1.mant-upc-2' },
            { slug: 'shoulderStructure', label: 'Structurer les épaules', id: '1.mant-upc-3' },
          ],
        },
        {
          slug: 'collar',
          label: 'Col',
          question: 'Pour le col vous voulez :',
          answers: [
            { slug: 'newCollarDesign', label: 'Revoir le design', id: '1.mant-upc-4' },
            { slug: 'collarCreation', label: 'Le créer', id: '1.mant-upc-5' },
          ],
        },
        {
          slug: 'global',
          label: 'Manteau entier',
          question: 'Pour le haut vous voulez :',
          answers: [
            { slug: 'bend', label: 'Le cintrer d’une taille ou plus intégralement', id: '1.mant-upc-6' },
            { slug: 'rigidify', label: 'Lui donner de la tenue', id: '1.mant-upc-7' },
          ],
        },
        {
          slug: 'custom',
          label: 'Éléments de personnalisation',
          question: 'Pour la personnalisation vous voulez ajouter :',
          answers: [
            { slug: 'zip', label: 'Un zip', id: '1.mant-upc-8', isMultiple: true },
            { slug: 'elbow', label: 'Des coudières (x2)', id: '1.mant-upc-9' },
            { slug: 'epaulette', label: 'Des épaulettes (x2)', id: '1.mant-upc-10' },
            { slug: 'tongs', label: 'Une pince', id: '1.mant-upc-11', isMultiple: true },
            { slug: 'pocket', label: 'Une poche', id: '1.mant-upc-12', isMultiple: true },
            { slug: 'button', label: 'Un bouton', id: '1.mant-upc-13', isMultiple: true },
            { slug: 'buttonhole', label: 'Une boutonnière', id: '1.mant-upc-14', isMultiple: true },
            { slug: 'passing', label: 'Un passant', id: '1.mant-upc-15', isMultiple: true },
          ],
        },
      ],
    },
  },
  haut: {
    name: 'Haut',
    categories: {
      slug: 'partOfPiece',
      question: 'Quelle partie du haut souhaitez-vous transformer ?',
      answers: [
        {
          slug: 'waist',
          label: 'Bustier / Taille',
          question: 'Sur le bustier vous voulez :',
          answers: [
            { slug: 'backOpening', label: 'Une ouverture dans le dos', id: '1.haut-upc-1' },
            { slug: 'neckline', label: 'Un décolleté', id: '1.haut-upc-2' },
          ],
        },
        {
          slug: 'sleeves',
          label: 'Manches / Épaules',
          question: 'Pour les manches vous voulez :',
          answers: [
            { slug: 'sleeveOrnament', label: 'Ajouter des ornements', id: '1.haut-upc-3' },
            { slug: 'sleeveCreation', label: 'Les créer', id: '1.haut-upc-4' },
            { slug: 'shoulderStructure', label: 'Structurer les épaules', id: '1.haut-upc-5' },
          ],
        },
        {
          slug: 'collar',
          label: 'Col',
          question: 'Pour le col vous voulez :',
          answers: [
            { slug: 'newCollarDesign', label: 'Revoir le design', id: '1.haut-upc-6' },
            { slug: 'collarCreation', label: 'Le créer', id: '1.haut-upc-7' },
          ],
        },
        {
          slug: 'global',
          label: 'Haut entier',
          question: 'Pour le haut vous voulez :',
          answers: [
            { slug: 'bend', label: 'Le cintrer d’une taille ou plus intégralement', id: '1.haut-upc-8' },
            { slug: 'rigidify', label: 'Lui donner de la tenue', id: '1.haut-upc-9' },
          ],
        },
        {
          slug: 'custom',
          label: 'Éléments de personnalisation',
          question: 'Pour la personnalisation vous voulez ajouter :',
          answers: [
            { slug: 'zip', label: 'Un zip', id: '1.haut-upc-10', isMultiple: true },
            { slug: 'invisibleZip', label: 'Un zip invisible', id: '1.haut-upc-11', isMultiple: true },
            { slug: 'shoulderStrap', label: 'Des bretelles (x2)', id: '1.haut-upc-12' },
            { slug: 'elbow', label: 'Des coudières (x2)', id: '1.haut-upc-13' },
            { slug: 'epaulette', label: 'Des épaulettes (x2)', id: '1.haut-upc-14' },
            { slug: 'wrist', label: 'Poignets (x2)', id: '1.haut-upc-15' },
            { slug: 'tongs', label: 'Une pince', id: '1.haut-upc-16', isMultiple: true },
            { slug: 'pocket', label: 'Une poche', id: '1.haut-upc-17', isMultiple: true },
            { slug: 'button', label: 'Un bouton', id: '1.haut-upc-18', isMultiple: true },
            { slug: 'buttonhole', label: 'Une boutonnière', id: '1.haut-upc-19', isMultiple: true },
            { slug: 'passing', label: 'Un passant', id: '1.haut-upc-20', isMultiple: true },
          ],
        },
      ],
    },
  },
  pant: {
    name: 'Pantalon',
    categories: {
      slug: 'partOfPiece',
      question: 'Quelle partie du pantalon souhaitez-vous transformer ?',
      answers: [
        {
          slug: 'global',
          label: 'Pantalon entier',
          question: 'Pour le pantalon vous voulez :',
          answers: [
            { slug: 'bend', label: 'Le cintrer de plus d’une taille', id: '1.pant-upc-1' },
            { slug: 'rigidify', label: 'Lui donner de la tenue', id: '1.pant-upc-2' },
          ],
        },
        {
          slug: 'custom',
          label: 'Éléments de personnalisation',
          question: 'Pour la personnalisation vous voulez ajouter :',
          answers: [
            { slug: 'zip', label: 'Un zip', id: '1.pant-upc-3', isMultiple: true },
            { slug: 'invisibleZip', label: 'Un zip invisible', id: '1.pant-upc-4', isMultiple: true },
            { slug: 'kneepads', label: 'Des genouillères (x2)', id: '1.pant-upc-5' },
            { slug: 'tongs', label: 'Une pince', id: '1.pant-upc-6', isMultiple: true },
            { slug: 'pocket', label: 'Une poche', id: '1.pant-upc-7', isMultiple: true },
            { slug: 'button', label: 'Un bouton', id: '1.pant-upc-8', isMultiple: true },
            { slug: 'buttonhole', label: 'Une boutonnière', id: '1.pant-upc-9', isMultiple: true },
            { slug: 'passing', label: 'Un passant', id: '1.pant-upc-10', isMultiple: true },
          ],
        },
      ],
    },
  },
  jupe: {
    name: 'Jupe',
    categories: {
      slug: 'partOfPiece',
      question: 'Quelle partie de la jupe souhaitez-vous transformer ?',
      answers: [
        {
          slug: 'global',
          label: 'Jupe entière',
          question: 'Pour la jupe vous voulez :',
          answers: [
            { slug: 'pants', label: 'La transformer en pantalon', id: '1.jupe-upc-1' },
            { slug: 'bend', label: 'La cintrer d’une taille ou plus intégralement', id: '1.jupe-upc-2' },
            { slug: 'rigidify', label: 'Lui donner de la tenue', id: '1.jupe-upc-3' },
          ],
        },
        {
          slug: 'custom',
          label: 'Éléments de personnalisation',
          question: 'Pour la personnalisation vous voulez ajouter :',
          answers: [
            { slug: 'zip', label: 'Un zip', id: '1.jupe-upc-4', isMultiple: true },
            { slug: 'invisibleZip', label: 'Un zip invisible', id: '1.jupe-upc-5', isMultiple: true },
            { slug: 'shoulderStrap', label: 'Des bretelles (x2)', id: '1.jupe-upc-6' },
            { slug: 'slit', label: 'Une fente', id: '1.jupe-upc-7', isMultiple: true },
            { slug: 'tongs', label: 'Une pince', id: '1.jupe-upc-8', isMultiple: true },
            { slug: 'pocket', label: 'Une poche', id: '1.jupe-upc-9', isMultiple: true },
            { slug: 'button', label: 'Un bouton', id: '1.jupe-upc-10', isMultiple: true },
            { slug: 'buttonhole', label: 'Une boutonnière', id: '1.jupe-upc-11', isMultiple: true },
            { slug: 'passing', label: 'Un passant', id: '1.jupe-upc-12', isMultiple: true },
          ],
        },
      ],
    },
  },
  robe: {
    name: 'Robe',
    categories: {
      slug: 'partOfPiece',
      question: 'Quelle partie de la robe souhaitez-vous transformer ?',
      answers: [
        {
          slug: 'waist',
          label: 'Bustier / Taille',
          question: 'Sur le bustier vous voulez :',
          answers: [
            { slug: 'backOpening', label: 'Une ouverture dans le dos', id: '1.robe-upc-1' },
            { slug: 'neckline', label: 'Un décolleté', id: '1.robe-upc-2' },
            { slug: 'split', label: 'Séparer le haut du bas', id: '1.robe-upc-3' },
          ],
        },
        {
          slug: 'sleeves',
          label: 'Manches / Épaules',
          question: 'Pour les manches vous voulez :',
          answers: [
            { slug: 'sleeveOrnament', label: 'Ajouter des ornements', id: '1.robe-upc-4' },
            { slug: 'sleeveCreation', label: 'Les créer', id: '1.robe-upc-5' },
            { slug: 'shoulderStructure', label: 'Structurer les épaules', id: '1.robe-upc-6' },
          ],
        },
        {
          slug: 'collar',
          label: 'Col',
          question: 'Pour le col vous voulez :',
          answers: [
            { slug: 'newCollarDesign', label: 'Revoir le design', id: '1.robe-upc-7' },
            { slug: 'collarCreation', label: 'Le créer', id: '1.robe-upc-8' },
          ],
        },
        {
          slug: 'bottom',
          label: 'Bas de robe',
          question: 'Pour le bas de la robe vous voulez :',
          answers: [
            { slug: 'pants', label: 'Le transformer en pantalon', id: '1.robe-upc-9' },
            { slug: 'split', label: 'Séparer le haut du bas', id: '1.robe-upc-3' },
          ],
        },
        {
          slug: 'global',
          label: 'Robe entière',
          question: 'Pour la robe vous voulez :',
          answers: [
            { slug: 'bend', label: 'La cintrer d’une taille ou plus intégralement', id: '1.robe-upc-11' },
            { slug: 'rigidify', label: 'Lui donner de la tenue', id: '1.robe-upc-12' },
            { slug: 'split', label: 'Séparer le haut du bas', id: '1.robe-upc-3' },
          ],
        },
        {
          slug: 'custom',
          label: 'Éléments de personnalisation',
          question: 'Pour la personnalisation vous voulez ajouter :',
          answers: [
            { slug: 'zip', label: 'Un zip', id: '1.robe-upc-14', isMultiple: true },
            { slug: 'invisibleZip', label: 'Un zip invisible', id: '1.robe-upc-15', isMultiple: true },
            { slug: 'shoulderStrap', label: 'Des bretelles (x2)', id: '1.robe-upc-16' },
            { slug: 'slit', label: 'Une fente', id: '1.robe-upc-17', isMultiple: true },
            { slug: 'tongs', label: 'Une pince', id: '1.robe-upc-18', isMultiple: true },
            { slug: 'pocket', label: 'Une poche', id: '1.robe-upc-19', isMultiple: true },
            { slug: 'button', label: 'Un bouton', id: '1.robe-upc-20', isMultiple: true },
            { slug: 'buttonhole', label: 'Une boutonnière', id: '1.robe-upc-21', isMultiple: true },
            { slug: 'passing', label: 'Un passant', id: '1.robe-upc-22', isMultiple: true },
          ],
        },
      ],
    },
  },
  mari: {
    name: 'Robe de mariée',
    categories: {
      slug: 'partOfPiece',
      question: 'Quelle partie de la robe souhaitez-vous transformer ?',
      answers: [
        {
          slug: 'waist',
          label: 'Bustier / Taille',
          question: 'Sur le bustier vous voulez :',
          answers: [
            { slug: 'backOpening', label: 'Une ouverture dans le dos', id: '1.mari-upc-1' },
            { slug: 'neckline', label: 'Un décolleté', id: '1.mari-upc-2' },
            { slug: 'split', label: 'Séparer le haut du bas', id: '1.mari-upc-3' },
            { slug: 'bendWaist', label: 'La cintrer d‘une taille ou plus', id: '1.mari-upc-4' },
          ],
        },
        {
          slug: 'sleeves',
          label: 'Manches / Épaules',
          question: 'Pour les manches vous voulez :',
          answers: [
            { slug: 'sleeveOrnament', label: 'Ajouter des ornements', id: '1.mari-upc-5' },
            { slug: 'sleeveCreation', label: 'Les créer', id: '1.mari-upc-6' },
            { slug: 'shoulderStructure', label: 'Structurer les épaules', id: '1.mari-upc-7' },
          ],
        },
        {
          slug: 'collar',
          label: 'Col',
          question: 'Pour le col vous voulez :',
          answers: [
            { slug: 'newCollarDesign', label: 'Revoir le design', id: '1.mari-upc-8' },
            { slug: 'collarCreation', label: 'Le créer', id: '1.mari-upc-9' },
          ],
        },
        {
          slug: 'bottom',
          label: 'Bas de robe',
          question: 'Pour le bas de la robe vous voulez :',
          answers: [
            { slug: 'pants', label: 'Le transformer en pantalon', id: '1.mari-upc-10' },
            { slug: 'split', label: 'Séparer le haut du bas', id: '1.mari-upc-3' },
          ],
        },
        {
          slug: 'global',
          label: 'Robe entière',
          question: 'Pour la robe vous voulez :',
          answers: [
            { slug: 'bend', label: 'La cintrer d’une taille ou plus intégralement', id: '1.mari-upc-12' },
            { slug: 'rigidify', label: 'Lui donner de la tenue', id: '1.mari-upc-13' },
          ],
        },
        {
          slug: 'custom',
          label: 'Éléments de personnalisation',
          question: 'Pour la personnalisation vous voulez ajouter :',
          answers: [
            { slug: 'invisibleZip', label: 'Un zip invisible', id: '1.mari-upc-14', isMultiple: true },
            { slug: 'shoulderStrap', label: 'Des bretelles (x2)', id: '1.mari-upc-15' },
            { slug: 'slit', label: 'Une fente', id: '1.mari-upc-16', isMultiple: true },
            { slug: 'tongs', label: 'Une pince', id: '1.mari-upc-17', isMultiple: true },
            { slug: 'pocket', label: 'Une poche', id: '1.mari-upc-18', isMultiple: true },
            { slug: 'button', label: 'Un bouton', id: '1.mari-upc-19', isMultiple: true },
            { slug: 'buttonhole', label: 'Une boutonnière', id: '1.mari-upc-20', isMultiple: true },
            { slug: 'passing', label: 'Un passant', id: '1.mari-upc-21' },
          ],
        },
      ],
    },
  },
  comb: {
    name: 'Combinaison',
    categories: {
      slug: 'partOfPiece',
      question: 'Quelle partie de la combinaison souhaitez-vous transformer ?',
      answers: [
        {
          slug: 'waist',
          label: 'Bustier / Taille',
          question: 'Sur le bustier vous voulez :',
          answers: [
            { slug: 'backOpening', label: 'Une ouverture dans le dos', id: '1.comb-upc-1' },
            { slug: 'neckline', label: 'Un décolleté', id: '1.comb-upc-2' },
            { slug: 'split', label: 'Séparer le haut du bas', id: '1.comb-upc-3' },
          ],
        },
        {
          slug: 'sleeves',
          label: 'Manches / Épaules',
          question: 'Pour les manches vous voulez :',
          answers: [
            { slug: 'sleeveOrnament', label: 'Ajouter des ornements', id: '1.comb-upc-4' },
            { slug: 'sleeveCreation', label: 'Les créer', id: '1.comb-upc-5' },
            { slug: 'shoulderStructure', label: 'Structurer les épaules', id: '1.comb-upc-6' },
          ],
        },
        {
          slug: 'collar',
          label: 'Col',
          question: 'Pour le col vous voulez :',
          answers: [
            { slug: 'newCollarDesign', label: 'Revoir le design', id: '1.comb-upc-7' },
            { slug: 'collarCreation', label: 'Le créer', id: '1.comb-upc-8' },
          ],
        },
        {
          slug: 'bottom',
          label: 'Bas de combinaison (jupe ou pantalon)',
          question: 'Pour le bas de la combinaison vous voulez :',
          answers: [
            { slug: 'pants', label: 'Le transformer en jupe', id: '1.comb-upc-9' },
            { slug: 'split', label: 'Séparer le haut du bas', id: '1.comb-upc-3' },
          ],
        },
        {
          slug: 'global',
          label: 'Combinaison entière',
          question: 'Pour la combinaison vous voulez :',
          answers: [
            { slug: 'bend', label: 'La cintrer d’une taille ou plus intégralement', id: '1.comb-upc-10' },
            { slug: 'rigidify', label: 'Lui donner de la tenue', id: '1.comb-upc-11' },
          ],
        },
        {
          slug: 'custom',
          label: 'Éléments de personnalisation',
          question: 'Pour la personnalisation vous voulez ajouter :',
          answers: [
            { slug: 'zip', label: 'Un zip', id: '1.comb-upc-12', isMultiple: true },
            { slug: 'invisibleZip', label: 'Un zip invisible', id: '1.comb-upc-13', isMultiple: true },
            { slug: 'shoulderStrap', label: 'Des bretelles (x2)', id: '1.comb-upc-14' },
            { slug: 'slit', label: 'Une fente', id: '1.comb-upc-15', isMultiple: true },
            { slug: 'tongs', label: 'Une pince', id: '1.comb-upc-16', isMultiple: true },
            { slug: 'pocket', label: 'Une poche', id: '1.comb-upc-17', isMultiple: true },
            { slug: 'button', label: 'Un bouton', id: '1.comb-upc-18', isMultiple: true },
            { slug: 'buttonhole', label: 'Une boutonnière', id: '1.comb-upc-19', isMultiple: true },
            { slug: 'passing', label: 'Un passant', id: '1.comb-upc-20' },
          ],
        },
      ],
    },
  },
};

const quantityQuestionsLabels = {
  zip: 'Combien de zips doivent être ajoutés ?',
  invisibleZip: 'Combien de zip invisibles doivent être ajoutés ?',
  slit: 'Combien de fentes doivent être ajoutées ?',
  tongs: 'Combien de pinces doivent être ajoutées ?',
  pocket: 'Combien de poches doivent être ajoutées ?',
  button: 'Combien de boutons doivent être ajoutés ?',
  buttonhole: 'Combien de boutonnières doivent être ajoutées ?',
  passing: 'Combien des passants doivent être ajoutés ?',
};

const treeUpcyclingInverse = {};
Object.values(treeUpcycling).forEach((piece) => {
  piece.categories.answers.forEach((category) => {
    category.answers.forEach((item) => {
      if (!treeUpcyclingInverse[item.id]) {
        treeUpcyclingInverse[item.id] = {
          ...item,
          categories: [category.slug],
        };
      } else {
        treeUpcyclingInverse[item.id].categories.push(category.slug);
      }
    });
  });
});
export { quantityQuestionsLabels, treeUpcyclingInverse };

export default treeUpcycling;
