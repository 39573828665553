import React from 'react';
import PropTypes from 'prop-types';

import UpcyclingCategoriesSelector from './UpcyclingCategoriesSelector';
import UpcyclingItemsSelector from './UpcyclingItemsSelector';
import UpcyclingItemQuantitySelector from './UpcyclingItemQuantitySelector';

const UpcyclingSelector = ({
  tree, upcyclingCategories, toggleUpcyclingCategory, upcyclingItems, toggleUpcyclingItem, createRange, setUpcyclingItemQuantity,
}) => (
  <div>
    <UpcyclingCategoriesSelector
      tree={tree.categories}
      upcyclingCategories={upcyclingCategories}
      toggleUpcyclingCategory={toggleUpcyclingCategory}
    />
    {upcyclingCategories.map((upcyclingCategory, index) => (
      <UpcyclingItemsSelector
        key={upcyclingCategory}
        tree={tree.categories.answers.find(({ slug }) => upcyclingCategory === slug)}
        isLast={(index === upcyclingCategories.length - 1) && upcyclingItems.filter(({ isMultiple }) => isMultiple).length < 1}
        category={upcyclingCategory}
        upcyclingItems={upcyclingItems}
        toggleUpcyclingItem={toggleUpcyclingItem}
      />
    ))}
    {upcyclingItems.filter(({ isMultiple }) => isMultiple).map(({ slug, id, quantity }, index, filteredUpcyclingItems) => (
      <UpcyclingItemQuantitySelector
        key={slug}
        createRange={createRange}
        slug={slug}
        id={id}
        setUpcyclingItemQuantity={setUpcyclingItemQuantity}
        quantity={quantity}
        isLast={index === filteredUpcyclingItems.length - 1}
      />
    ))
    }
  </div>
);

UpcyclingSelector.propTypes = {
  tree: PropTypes.shape({}),
  upcyclingCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleUpcyclingCategory: PropTypes.func.isRequired,
  upcyclingItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleUpcyclingItem: PropTypes.func.isRequired,
  createRange: PropTypes.func.isRequired,
  setUpcyclingItemQuantity: PropTypes.func.isRequired,
};

UpcyclingSelector.defaultProps = {
  tree: undefined,
};

export default UpcyclingSelector;
