import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Row as RowOrder,
  Content,
  CardsContainer as CardsContainerOrder,
  fontSizes,
  colors,
  mobileThresholdPixels,
  Subtitle as Title,
} from './orderStyledComponents';
import CardSelector from './CardSelector';

import Dots from '../home/v3/Dots';

const CardsContainer = styled(CardsContainerOrder)`
  margin-top: 51px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${fontSizes.m};
  line-height: 28px;
  font-family: Roboto;
  color: ${colors.navy};
  max-width: 594px;
  align-self: center;
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 17px;
    width: 290px;
  }
`;

const Row = styled(RowOrder)`
  margin-top: 0px;
`;

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Step1AccessoryCreations = ({
  accessoryCreations, selectAccessoryCreation, selectedAccessoryCreation,
}) => (
  <Row smallMarginTop>
    <Content marginTop={20}>
      <Title>Quelle pièce souhaitez-vous créer ?</Title>
      <Dots justifyContent="center" noMargin />
      <Subtitle>
        Tilli réalise désormais vos accessoires sur demande à partir de vos propres tissus ou ceux de nos partenaires.
      </Subtitle>
      <Row2>
        <CardsContainer center>
          {accessoryCreations.map(accessoryCreation => (
            <CardSelector
              key={accessoryCreation.slug}
              slug={accessoryCreation.slug}
              image={accessoryCreation.image}
              label={accessoryCreation.name}
              alt={`Tilli crée des ${accessoryCreation.name}s`}
              onClick={() => selectAccessoryCreation(accessoryCreation.slug, accessoryCreation.noHandleOption)}
              littleCard
              active={selectedAccessoryCreation === accessoryCreation.slug}
            />),
          )}
        </CardsContainer>
      </Row2>
    </Content>
  </Row>
);

Step1AccessoryCreations.propTypes = {
  accessoryCreations: PropTypes.arrayOf(PropTypes.shape({})),
  selectAccessoryCreation: PropTypes.func,
  selectedAccessoryCreation: PropTypes.string,
};

Step1AccessoryCreations.defaultProps = {
  accessoryCreations: [],
  selectAccessoryCreation() { },
  selectedAccessoryCreation: '',
};

export default Step1AccessoryCreations;
