import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import { mobileThresholdPixels, fontSizes, colors } from '../styledComponents';
import Dots from '../home/v3/Dots';
import { Row, Title, Subtitle } from './orderStyledComponents';
import CardSelector from './CardSelector';

import clothesPhoto from '../../assets/order/categories/clothes.jpg';
import decoPhoto from '../../assets/order/categories/deco.jpg';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 40px 0px 40px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 10px 0px;
  }
`;

const Title2 = styled.section`
  font-family: Libre Baskerville;
  line-height: 30px;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: ${colors.white};
  margin: 30px 37px 10px 37px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    padding: 15px 23px 0px 23px;
    margin: auto;
    line-height: 15px;
  }
`;

const Wrapper = styled.section`
  min-height: 260px;
  height: 260px;
  width: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background: ${colors.navy};
  box-shadow: 0px 1px 6px ${colors.boxShadow};

  @media (max-width: ${mobileThresholdPixels}) {
    position: relative;
    height: auto;
    width: 100%;
  }
`;

const BlueWrapper = styled.div`
  height: 210px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 120px;
  }
`;

const TextContainer = styled.div`
  background-color:${colors.white};
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 25px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
`;

const TextButton = styled.section`
  font-family: Libre Baskerville;
  line-height: 25px;
  font-weight: 600;
  font-size: 15px;
  color: ${colors.navy};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const Text = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: regular;
  line-height: 25px;
  font-size: ${fontSizes.s};
  text-align: center;
  color: ${colors.white};
  margin: 10px 37px 26px 37px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 9px 15px 9px;
    line-height: 15px;
    font-size: 12px;
  }
`;

const Step1Form = ({ category, selectCategory }) => (
  <div>
    <Column>
      <Title>
        Dites-nous ce dont vous avez besoin en 2 min chrono
      </Title>
      <Subtitle>Choisissez la catégorie</Subtitle>
      <Dots justifyContent="center" noMargin />
    </Column>
    <Row smallMarginTop mobileMarginTop={0.01}>
      <Column>
        <CardSelector
          slug="clothes"
          image={clothesPhoto}
          alt="Vêtements et accessoires"
          label="Vêtements et accessoires"
          onClick={() => selectCategory('clothes')}
          active={category === 'clothes'}
        />
      </Column>
      <Column>
        <CardSelector
          slug="deco"
          image={decoPhoto}
          alt="Décoration et linge de maison"
          label="Décoration et linge de maison"
          onClick={() => selectCategory('deco')}
          active={category === 'deco'}
        />
      </Column>
      <Column>
        <Wrapper onClick={() => selectCategory('RDV3Clicks')}>
          <BlueWrapper>
            <Title2>Vous connaissez déjà <br /> le concept ?</Title2>
            <Dots color={colors.white} justifyContent="center" noMargin />
            <Text>
              Prenez RDV et expliquez vos besoins à votre couturier directement sur place.
            </Text>
          </BlueWrapper>
          <TextContainer>
            <TextButton>
              RDV en 3 clics
            </TextButton>
          </TextContainer>
        </Wrapper>
      </Column>
    </Row>
  </div>
);

Step1Form.propTypes = {
  category: PropTypes.string,
  selectCategory: PropTypes.func.isRequired,
};

Step1Form.defaultProps = {
  category: null,
};

export default withAppContext(Step1Form);
