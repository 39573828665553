import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  StepContainer, TreeRow, Label, Row2, SmallContent, Question, AnswerCard, mobileThresholdPixels,
} from './orderStyledComponents';
import ProgressBarVertical from '../order/ProgressBarVertical';
import { treeUpcyclingInverse } from './treeUpcycling';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
  }
`;

const UpcyclingItemsSelector = ({ tree, category, upcyclingItems, isLast, toggleUpcyclingItem }) => {
  let progressBarHeight = 200;
  if (tree.answers.length < 4) progressBarHeight = 146;
  if (tree.answers.length > 5) progressBarHeight = 254;
  return (
    <TreeRow>
      <StepContainer>
        <ProgressBarVertical
          isActive={!!upcyclingItems.find(({ id }) => treeUpcyclingInverse[id].categories.includes(category))}
          height={progressBarHeight}
          noLine={isLast}
        />
        <SmallContent flexStart marginLeft>
          <Question>{tree.question}</Question>
          <Row2>
            <CardsContainer>
              {tree.answers.map(item => (
                <AnswerCard
                  key={item.id}
                  slug={item.slug}
                  onClick={() => toggleUpcyclingItem(item)}
                  isActive={upcyclingItems.find(({ id }) => id === item.id)}
                >
                  <Label>
                    {item.label}
                  </Label>
                </AnswerCard>),
              )}
            </CardsContainer>
          </Row2>
        </SmallContent>
      </StepContainer>
    </TreeRow>
  )
  ;
};

UpcyclingItemsSelector.propTypes = {
  tree: PropTypes.shape({}).isRequired,
  category: PropTypes.string.isRequired,
  upcyclingItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLast: PropTypes.bool.isRequired,
  toggleUpcyclingItem: PropTypes.func.isRequired,
};

export default UpcyclingItemsSelector;
