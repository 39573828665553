import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  StepContainer, TreeRow, Label, Row2, SmallContent, Question, AnswerCard, mobileThresholdPixels,
} from './orderStyledComponents';
import ProgressBarVertical from '../order/ProgressBarVertical';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
  }
`;

const Step1Problem = ({ treeProblems: { problems }, selectProblems, selectedProblems, selectedCloth }) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical
        isActive={Object.keys(selectedProblems).length > 0}
        height={problems.length < 4 ? 146 : 200}
        noLine={
          selectedCloth !== 'acce'
          && (Object.keys(selectedProblems).length === 0
          || (Object.keys(selectedProblems).length === 1 && Object.keys(selectedProblems)[0] === 'talkRDV'))
        }
      />
      <SmallContent flexStart marginLeft>
        <Question>Indiquez-nous le problème :</Question>
        <Row2>
          <CardsContainer>
            {problems.map(problem => (
              <AnswerCard
                key={problem.slug}
                slug={problem.slug}
                onClick={() => selectProblems(problem.slug)}
                isActive={typeof selectedProblems[problem.slug] === 'object'}
              >
                <Label>
                  {problem.label}
                </Label>
              </AnswerCard>),
            )}
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

Step1Problem.propTypes = {
  selectProblems: PropTypes.func,
  selectedProblems: PropTypes.shape({
    problems: PropTypes.array,
  }),
  treeProblems: PropTypes.shape({
    problems: PropTypes.array,
  }),
  selectedCloth: PropTypes.string,
};

Step1Problem.defaultProps = {
  selectProblems() { },
  selectedProblems: {},
  treeProblems: {},
  selectedCloth: '',
};

export default Step1Problem;
