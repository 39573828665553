import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import {
  Row as RowOrder, Content, fontSizes, colors, mobileThresholdPixels,
} from './orderStyledComponents';

import { inspirations } from './upcyclingInspirationsData';

const CardsContainer = styled(RowOrder)`
  margin-top: 51px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const Row = styled(RowOrder)`
  margin-top: 0px;
`;

const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${fontSizes.m};
  line-height: 28px;
  font-family: Roboto;
  color: ${colors.navy};
  max-width: 594px;
  align-self: center;
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 17px;
    width: 290px;
  }
`;

const Card = styled.div`
  position: relative;
  box-shadow: 0px 1px 6px ${colors.whiteTransparent};
  width: 345px;
  height: 260px;
  margin: 0px 10px 53px 10px;
  overflow: hidden;
  &:hover ${props => `#${props.cardId}`}{
    bottom: 0px;
    transition: bottom 0.8s ease;
  }
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    height: auto;
  }
`;

const CardImg = styled.img`
  width: inherit;
  height: inherit;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: inherit;
  height: 206px;
  margin-top: 54px;
  background-color: rgba(23, 44, 66, 0.65);
  bottom: -206px;
  transition: bottom 0.5s ease;
  @media (max-width: ${mobileThresholdPixels}) {
    height: inherit;
  }
`;

const CardSpan = styled.span`
  color: ${colors.white};
  font-family: Roboto;
  font-size: 15px;
  line-height: 120%;
  margin: 20px 30px 18px;
  height: 72px;
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 15px;
    font-size: 12px;
    margin: 15px 15px 13px;
  }
`;

const CardEstimatedPriceSpan = styled(CardSpan)`
  margin: 0px 30px 30px;
  height: auto;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 15px 15px;
  }
`;

const CardTitle = styled(CardSpan)`
  text-align: center;
  font-weight: bold;
  margin: 30px 30px 0px;
  height: auto;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 26px 15px 0px;
  }
`;

function getDots(isMobile, price) {
  const dots = '......................................';
  const initialLength = isMobile ? 34 : 38;
  const length = initialLength - Math.round(price.length * 1.5); // 1.5 because dots are smaller than price chars
  return dots.slice(0, length);
}

const Step1UpcyclingInspirations = ({ selectedCloth, selectedFabric, context: { isMobile } }) => {
  const selectedInspirations = !selectedFabric || !inspirations[selectedCloth] ? null : inspirations[selectedCloth][selectedFabric];

  if (!selectedInspirations) return <div />;

  return (
    <Row smallMarginTop>
      <Content marginTop={20}>
        <Subtitle>
          Inspirez-vous et discutez de votre projet d’upcycling avec votre couturier en RDV
        </Subtitle>
        <Row>
          <CardsContainer center>
            {selectedInspirations.map((inspiration, index) =>
              <Card key={`${inspiration.image}${selectedFabric}`} cardId={`card${index}`}>
                <CardImg src={inspiration.image} alt={inspiration.content.title} />
                {inspiration.content.title && <CardContent id={`card${index}`}>
                  <CardTitle>{inspiration.content.title}</CardTitle>
                  <CardSpan>{inspiration.content.text}</CardSpan>
                  <CardEstimatedPriceSpan>
                    Tarif indicatif{' '}
                    {getDots(isMobile, inspiration.content.estimatedPrice)}{' '}
                    {inspiration.content.estimatedPrice}
                  </CardEstimatedPriceSpan>
                </CardContent>}
              </Card>,
            )}
          </CardsContainer>
        </Row>
      </Content>
    </Row>
  );
};

Step1UpcyclingInspirations.propTypes = {
  selectedCloth: PropTypes.string.isRequired,
  selectedFabric: PropTypes.string.isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(Step1UpcyclingInspirations);
