import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { ComponentsContainer } from './home/v3/styledComponents';
import routesMap from '../Routes';
import withOrderContext from '../withOrderContext';
import tree, { multipleQuestions, treeAlterationSuit } from './order/tree';
import ArrowButton from './order/LogoButton';
import OrderSummaryContainer from './order/OrderSummaryContainer';
import Step1Form from './order/Step1Form';
import { getTreeLocations, getTreeProblems } from '../services/treeSelector';
import { pushToLayer } from '../services/googleTagManager';
import initFullstory from '../services/fullstory';
import getInitialState, {
  getList, initialState, embroideries, linensAlterations, linensSizes, linensWhere,
} from './step1InitialState';
import { treeUpcyclingInverse } from './order/treeUpcycling';
import {
  StepContainer1, StepContainer2, Content, StyledButton, StyledLink,
} from './step1StyledComponents';
import { scrollToStep, shouldDisplayLining, isSelectingDone } from './step1Utils';

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    const { orderContext: { rdv3ClicksOrder, editingCloth } } = this.props;
    if (rdv3ClicksOrder?.knowHow?.length > 0) {
      this.state = { ...getInitialState(), selectedCategory: 'RDV3Clicks' };
    } else {
      this.state = editingCloth
        ? {
          ...getInitialState(props.initialState),
          ...editingCloth,
        }
        : getInitialState(props.initialState);
    }

    pushToLayer({ funnel_type: 'standard', is_logged_in: false });
    if (process.env.GATSBY_ENV === 'PROD') initFullstory();
  }

  componentDidMount() {
    const { selectedCategory, selectedAccessoryCreation } = this.state;
    const { clothes = [] } = this.props.orderContext;

    if ((selectedCategory === 'clothes') || selectedAccessoryCreation) {
      scrollToStep(this.Step1Fabrics, true);
    } else if (selectedCategory === 'deco') {
      scrollToStep(this.Step1Piece, true);
    } else if (selectedCategory === 'RDV3Clicks') {
      scrollToStep(this.Step1Top, true);
    } else if (clothes.length > 0) {
      this.setDisplayButtons(true);
    }
  }

  getGeneralProps = () => {
    const {
      selectedCategory, selectedTypeOfWork, selectedCloth, selectedPiece, selectedFabric, isFurSelected, selectedProblems, selectedLining, numberOfPieces,
    } = this.state;
    return { selectedCategory, selectedCloth, selectedPiece, selectedFabric, isFurSelected, selectedTypeOfWork, selectedProblems, selectedLining, numberOfPieces };
  }

  getAccessoryProps = () => {
    const {
      selectedAccessoryCreation, selectedBagShape, selectedCreationNumberOfPieces, selectedBagSize, selectedBagHandle, selectedBagClosing, noHandleOption,
    } = this.state;
    return { selectedAccessoryCreation, selectedBagShape, selectedCreationNumberOfPieces, selectedBagSize, selectedBagHandle, selectedBagClosing, noHandleOption };
  }

  getEmbroideryProps = () => {
    const {
      embroideries: embroideriesState, activeEmbroideryFontSize, activeEmbroideryDrawingSize, isDrawingFull, isDrawingEasy,
      selectedFabricOfAccessoriesCreation,
    } = this.state;
    return {
      embroideries: embroideriesState,
      activeEmbroideryFontSize,
      activeEmbroideryDrawingSize,
      isDrawingFull,
      isDrawingEasy,
      selectedFabricOfAccessoriesCreation,
    };
  }

  getCushionProps = () => {
    const { isCushionSmall, cushionHasZip, isCushionAlterationSelected, cushionAlterations } = this.state;
    return { isCushionSmall, cushionHasZip, isCushionAlterationSelected, cushionAlterations };
  }

  getCurtainProps = () => {
    const { activeCurtainAdjustment, isCurtainLined, curtainAlterations } = this.state;
    return { activeCurtainAdjustment, isCurtainLined, curtainAlterations };
  }

  getLinenProps = () => {
    const { linensProblems, linensAlterations: alterations, linensSizes: sizes, linensWhere: where } = this.state;
    return { linensProblems, linensAlterations: alterations, linensSizes: sizes, linensWhere: where };
  }

  getSuitProps = () => {
    const { suitPieces, suitProblems, suitItems, suitPieceLinings } = this.state;
    return { suitPieces, suitProblems, suitItems, suitPieceLinings };
  }

  getUpcyclingProps = () => {
    const { upcyclingCategories, upcyclingItems } = this.state;
    return { upcyclingCategories, upcyclingItems };
  }

  setDisplayButtons = (displayButtons) => {
    this.setState({ displayButtons });
  }

  setEditingCloth = () => {
    const { orderContext: { editingCloth } } = this.props;
    this.setState({
      ...getInitialState(),
      ...editingCloth,
    });
  }

  setSelect = (selectedValue, stateName, isQuestionInsideState = false, index = -1) => {
    if (!isQuestionInsideState) {
      this.setState({ [stateName]: selectedValue });
    } else {
      const list = [...this.state[stateName]];
      const question = { ...list[index].question };
      question.answer = selectedValue;
      list[index].question = { ...question };
      this.setState({ [stateName]: [...list] });
    }
  }

  setSingleChoice = (active, stateName, isList = false) => {
    if (isList) {
      const list = [...this.state[stateName]];
      const newList = list.map((item, index) => ({ ...item, isActive: index === active }));
      this.setState({ [stateName]: [...newList] });
    } else {
      this.setState({ [stateName]: active });
      if (stateName === 'activeEmbroideryDrawingSize') {
        this.setState({ isDrawingFull: undefined });
      } else if (stateName === 'isDrawingFull') {
        this.setState({ isDrawingEasy: undefined });
      }
    }
  }

  setMultipleChoices = (index, listName) => {
    const newList = [...this.state[listName]];
    newList[index].isActive = !newList[index].isActive;
    this.setState({ [listName]: [...newList] });

    if (listName === 'curtainAlterations' && !newList[index].isActive) {
      if (index === 0) {
        this.setState({ activeCurtainAdjustment: -1 });
      }
    }

    if (listName === 'cushionAlterations') {
      if (newList[index].isActive) {
        this.setState({ isCushionAlterationSelected: true });
      } else {
        const isCushionAlterationSelected = newList.reduce((acc, alteration) => acc || alteration.isActive, false);
        this.setState({ isCushionAlterationSelected });
      }
    }

    if (listName === 'embroideries' && !newList[index].isActive) {
      if (index === 1) {
        this.setState({
          activeEmbroideryFontSize: -1,
        });
      } else if (index === 2) {
        this.setState({
          activeEmbroideryDrawingSize: -1,
          isDrawingFull: undefined,
          isDrawingEasy: undefined,
        });
      }
    }

    if (listName === 'linensProblems' && !newList[index].isActive) {
      if (index === 0) {
        this.setState({
          linensAlterations: getList(linensAlterations),
        });
      } else {
        this.setState({
          linensSizes: getList(linensSizes),
          linensWhere: getList(linensWhere),
        });
      }
    }
  }

  setSelectValue = (selectedValue, slug) => {
    const selectedProblems = { ...this.state.selectedProblems };
    selectedProblems[slug] = [selectedValue];
    this.setState({ selectedProblems });
  }

  getClothesForSummary = () => {
    const { clothes = [], editingClothIndex } = this.props.orderContext;
    if (typeof editingClothIndex === 'number') {
      return clothes.map((cloth, index) => index === editingClothIndex ? this.state : cloth);
    }
    return [...clothes, { ...this.state, isCurrent: true }];
  }

  getSelectedClothesForSummary = () => {
    const { clothes = [] } = this.props.orderContext;
    return clothes;
  }

  setUpcyclingItemQuantity = (itemId, quantity) => {
    const { upcyclingItems: upcyclingItemsState } = this.state;
    const upcyclingItems = upcyclingItemsState.map((upcyclingItem) => {
      if (itemId !== upcyclingItem.id) return upcyclingItem;
      return {
        ...upcyclingItem,
        quantity,
      };
    });
    this.setState({ upcyclingItems });
  }

  setSuitItemQuantity = (quantity, itemId) => {
    const { suitItems: suitItemsState } = this.state;
    const suitItems = suitItemsState.map((suitItem) => {
      if (itemId !== suitItem.id) return suitItem;
      return {
        ...suitItem,
        quantity,
      };
    });
    this.setState({ suitItems });
  }

  selectAccessoryCreationsOptionsForBags = (stateName, value) => {
    const selectedBagSize = stateName === 'selectedBagSize' ? value : this.state.selectedBagSize;
    const selectedBagShape = stateName === 'selectedBagShape' ? value : this.state.selectedBagShape;
    const selectedCreationNumberOfPieces = stateName === 'selectedCreationNumberOfPieces'
      ? value : this.state.selectedCreationNumberOfPieces;
    const selectedBagHandle = stateName === 'selectedBagHandle' ? value : this.state.selectedBagHandle;
    const selectedBagClosing = stateName === 'selectedBagClosing' ? value : this.state.selectedBagClosing;
    this.setState({
      ...getInitialState(),
      selectedCategory: this.state.selectedCategory,
      selectedCloth: this.state.selectedCloth,
      selectedFabric: this.state.selectedFabric,
      isFurSelected: this.state.isFurSelected,
      selectedPiece: this.state.selectedPiece,
      selectedTypeOfWork: this.state.selectedTypeOfWork,
      selectedAccessoryCreation: this.state.selectedAccessoryCreation,
      selectedBagShape,
      selectedCreationNumberOfPieces,
      selectedBagSize,
      selectedBagHandle,
      selectedBagClosing,
      selectedFabricOfAccessoriesCreation: this.state.selectedFabricOfAccessoriesCreation,
    });
  }

  saveCloth = () => {
    const { addOrEditCloth } = this.props.orderContext;
    if (isSelectingDone(this.state)) {
      addOrEditCloth({
        ...this.getGeneralProps(),
        ...this.getAccessoryProps(),
        ...this.getEmbroideryProps(),
        ...this.getCushionProps(),
        ...this.getCurtainProps(),
        ...this.getLinenProps(),
        ...this.getUpcyclingProps(),
        ...this.getSuitProps(),
      });
    }
  }

  goNext = () => {
    this.saveCloth();
    this.setState(getInitialState());
    navigate(routesMap.Step2.url);
  }

  addPiece = () => {
    pushToLayer({ event: 'Order Funnel - Extra Item Button Clicked' });
    this.saveCloth();
    this.setState(getInitialState());
    scrollTo(0, 0);
  }

  resetEmbroideries = () => {
    this.setState({
      embroideries: getList(embroideries),
      activeEmbroideryFontSize: -1,
      activeEmbroideryDrawingSize: -1,
      isDrawingFull: undefined,
      isDrawingEasy: undefined,
    });
  }

  selectCategory = (selectedCategory) => {
    this.setState({
      ...initialState,
      embroideries: getList(embroideries),
      selectedCategory,
    }, () => {
      if (selectedCategory !== 'RDV3Clicks' && selectedCategory !== '') {
        pushToLayer({ funnel_type: 'standard' });
        pushToLayer({
          event: 'Order Funnel - Category Chosen',
          category: selectedCategory === 'clothes' ? 'mode' : 'décoration',
        });
        scrollToStep(this.Step1TypeOfWork);
      } else {
        pushToLayer({
          event: 'Order Funnel Started',
          from: window.location.pathname,
          from_interaction: 'lite_button',
          funnel_type: 'lite',
        });
        const { orderContext: { setRDV3ClicksOrder } } = this.props;
        setRDV3ClicksOrder([], [], [], false);
        window.scrollTo(0, 0);
      }
    });
  }

  selectCloth = (slug) => {
    pushToLayer({
      event: 'Order Funnel - Item Chosen',
      item: slug,
    });
    this.setState({
      ...getInitialState(),
      selectedCategory: this.state.selectedCategory,
      selectedCloth: slug,
      selectedTypeOfWork: this.state.selectedTypeOfWork,
    }, () => scrollToStep(this.Step1Fabrics));
  }

  selectPiece = (selectedPiece) => {
    pushToLayer({
      event: 'Order Funnel - Item Chosen',
      item: selectedPiece,
    });
    this.setState({
      ...getInitialState(),
      selectedCategory: this.state.selectedCategory,
      selectedPiece,
      numberOfPieces: 1,
      selectedTypeOfWork: this.state.selectedTypeOfWork,
    }, () => scrollToStep(this.Step1Fabrics));
  }

  selectFabric = (slug) => {
    pushToLayer({
      event: 'Order Funnel - Fabric Chosen',
      fabric: slug,
    });
    if (slug === 'fur') {
      this.setState({ isFurSelected: true });
    } else {
      this.setState({
        ...getInitialState(),
        selectedCategory: this.state.selectedCategory,
        selectedCloth: this.state.selectedCloth,
        selectedFabric: slug,
        isFurSelected: this.state.isFurSelected,
        selectedTypeOfWork: this.state.selectedTypeOfWork,
      }, () => scrollToStep(this.Step1AdditionalInformation));
    }
  }

  selectTypeOfWork = (slug) => {
    pushToLayer({
      event: 'Order Funnel - Order Type Chosen',
      order_type: slug,
    });
    pushToLayer({
      event: 'Order Funnel - First Question Displayed',
    });

    this.setState({
      ...getInitialState(),
      selectedCategory: this.state.selectedCategory,
      selectedTypeOfWork: slug,
    }, () => scrollToStep(this.Step1Piece));
  }

  selectAccessoryCreation = (slug, noHandleOption) => {
    this.setState({
      ...getInitialState(),
      noHandleOption,
      selectedAccessoryCreation: slug,
      selectedCategory: this.state.selectedCategory,
      selectedTypeOfWork: this.state.selectedTypeOfWork,
    }, () => scrollToStep(this.Step1Fabrics));
  }

  selectFabricOfAccessoriesCreation = (slug) => {
    let selectedFabricOfAccessoriesCreation = [...this.state.selectedFabricOfAccessoriesCreation];

    if (selectedFabricOfAccessoriesCreation.includes(slug)) {
      selectedFabricOfAccessoriesCreation = selectedFabricOfAccessoriesCreation.filter(fabric => fabric !== slug);
    } else {
      selectedFabricOfAccessoriesCreation = [...selectedFabricOfAccessoriesCreation, slug];
    }

    this.setState({
      ...getInitialState(),
      noHandleOption: this.state.noHandleOption,
      selectedAccessoryCreation: this.state.selectedAccessoryCreation,
      selectedCategory: this.state.selectedCategory,
      selectedFabricOfAccessoriesCreation,
      selectedTypeOfWork: this.state.selectedTypeOfWork,
    });
  }

  toggleSuitPiece = (suitPiece) => {
    let suitPieces = [...this.state.suitPieces];
    let suitProblems = [...this.state.suitProblems];
    let suitItems = [...this.state.suitItems];
    const suitPieceLinings = { ...this.state.suitPieceLinings };
    if (suitPieces.includes(suitPiece)) {
      suitPieces = suitPieces.filter(piece => piece !== suitPiece);
      suitProblems = suitProblems.filter(suitProblem => !suitProblem.includes(`${suitPiece}_`));
      suitItems = suitItems.filter(suitItem => !suitItem.slug.includes(`cost_${suitPiece}_`));
      delete suitPieceLinings[suitPiece];
    } else {
      suitPieces.push(suitPiece);
    }

    this.setState({
      ...getInitialState(),
      selectedCategory: this.state.selectedCategory,
      selectedCloth: this.state.selectedCloth,
      selectedFabric: this.state.selectedFabric,
      isFurSelected: this.state.isFurSelected,
      selectedTypeOfWork: this.state.selectedTypeOfWork,
      suitPieces,
      suitProblems,
      suitItems,
      suitPieceLinings,
    });
  }

  toggleSuitProblem = (suitPiece, suitProblem) => {
    let suitProblems = [...this.state.suitProblems];
    let suitItems = [...this.state.suitItems];
    const suitPieceLinings = { ...this.state.suitPieceLinings };
    const suitProblemSlug = suitProblem.replace(`${suitPiece}_`, '');
    if (suitProblems.includes(suitProblem)) {
      suitProblems = suitProblems.filter(problem => problem !== suitProblem);
      if (suitProblems.length === 0) {
        suitItems = [];
        delete suitPieceLinings[suitPiece];
      } else {
        suitItems = suitItems.filter(({ piece, problem }) => (piece !== suitPiece || problem !== suitProblemSlug));
        if (suitItems.filter(({ piece }) => piece === suitPiece).length === 0) delete suitPieceLinings[suitPiece];
      }
    } else {
      suitProblems.push(suitProblem);
      if (treeAlterationSuit.pieces[suitPiece].problems[suitProblemSlug].answers?.length === 1) {
        suitItems.push({
          ...treeAlterationSuit.pieces[suitPiece].problems[suitProblemSlug].answers[0],
          piece: suitPiece,
          problem: suitProblemSlug,
        });
      }
    }

    this.setState({ suitProblems, suitItems, suitPieceLinings });
  }

  toggleSuitItem = (suitPiece, suitProblem, item) => {
    let suitItems = [...this.state.suitItems];
    const suitPieceLinings = { ...this.state.suitPieceLinings };
    if (suitItems.find(({ id }) => id === item.id)) {
      suitItems = suitItems.filter(({ id }) => id !== item.id);
      if (suitItems.filter(({ piece, problem }) => piece === suitPiece && problem === suitProblem).length === 0) delete suitPieceLinings[suitPiece];
    } else {
      suitItems.push({
        ...item,
        piece: suitPiece,
        problem: suitProblem,
      });
      if (item.isMultiple) suitItems[suitItems.length - 1].quantity = { value: 1, label: '1' };
    }
    this.setState({ suitItems, suitPieceLinings });
  }

  selectProblems = (problemsSlug, locationsTree = {}) => {
    let newSelectedProblems = { ...this.state.selectedProblems };
    const locationsSlugs = newSelectedProblems[problemsSlug];
    const { locations = [] } = locationsTree;
    if (typeof locationsSlugs === 'object') {
      newSelectedProblems[problemsSlug].forEach((location) => {
        if (Object.keys(newSelectedProblems).includes(`${location}_multiple`)) {
          delete newSelectedProblems[`${location}_multiple`];
        }
      });
      delete newSelectedProblems[problemsSlug];
      if (!shouldDisplayLining(newSelectedProblems)) {
        this.setState({ selectedLining: '' });
      }
    } else {
      let newLocations = [];
      if (problemsSlug.includes('acce')) newLocations = [{ value: 1, label: '1' }];
      if (locations.length === 1) newLocations = [locations[0].slug];
      newSelectedProblems = {
        ...newSelectedProblems,
        [problemsSlug]: newLocations,
      };
    }
    this.setState({ selectedProblems: newSelectedProblems });
  }

  selectLocations = (problemSlug, locationSlug) => {
    let selectedProblems = { ...this.state.selectedProblems };
    const slugIndex = selectedProblems[problemSlug].findIndex(location => location === locationSlug);
    if (slugIndex >= 0) {
      selectedProblems[problemSlug].splice(slugIndex, 1);
      if (Object.keys(multipleQuestions).includes(locationSlug)) {
        delete selectedProblems[`${locationSlug}_multiple`];
      }
      if (!shouldDisplayLining(selectedProblems)) {
        this.setState({ selectedLining: '' });
      }
    } else {
      selectedProblems[problemSlug].push(locationSlug);
      if (Object.keys(multipleQuestions).includes(locationSlug)) {
        selectedProblems = { ...selectedProblems, [`${locationSlug}_multiple`]: [{ value: 1, label: '1' }] };
      }
    }
    this.setState({ selectedProblems: { ...selectedProblems } });
  }

  toggleUpcyclingCategory = (slug) => {
    let upcyclingCategories = [...this.state.upcyclingCategories];
    let upcyclingItems = [...this.state.upcyclingItems];
    if (upcyclingCategories.includes(slug)) {
      upcyclingCategories = upcyclingCategories.filter(upcyclingCategory => upcyclingCategory !== slug);
      if (upcyclingCategories.length === 0) {
        upcyclingItems = [];
      } else {
        upcyclingItems = upcyclingItems.filter(({ id }) => {
          const itemCategories = treeUpcyclingInverse[id].categories;
          if (itemCategories.includes(slug)) {
            if (itemCategories.length === 1) return false;
            const mustBeRemoved = upcyclingCategories.reduce((acc, upcyclingCategory) => acc && itemCategories.includes(upcyclingCategory), true);
            return mustBeRemoved;
          }
          return true;
        });
      }
    } else {
      upcyclingCategories.push(slug);
    }
    this.setState({ upcyclingCategories, upcyclingItems });
  }

  toggleUpcyclingItem = (item) => {
    let upcyclingItems = [...this.state.upcyclingItems];
    if (upcyclingItems.find(({ id }) => id === item.id)) {
      upcyclingItems = upcyclingItems.filter(({ id }) => id !== item.id);
    } else {
      upcyclingItems.push(item);
      if (item.isMultiple) upcyclingItems[upcyclingItems.length - 1].quantity = { value: 1, label: '1' };
    }
    this.setState({ upcyclingItems });
  }

  selectLining = (haveLining) => {
    this.setState({ selectedLining: haveLining });
  }

  selectSuitPieceLining = (piece, haveLining) => {
    const suitPieceLinings = { ...this.state.suitPieceLinings };
    suitPieceLinings[piece] = haveLining;
    this.setState({ suitPieceLinings });
  }

  trackNeedsDescribed = (totalPaid) => {
    const { needsDescribed } = this.state;
    if (!needsDescribed) {
      this.setState({ needsDescribed: true });
      pushToLayer({
        event: 'Order Funnel - Needs Described',
        order_amount: Math.round(totalPaid * 100),
      });
    }
  }

  displaySummary = () => {
    const { clothes = [] } = this.props.orderContext;
    if (isSelectingDone(this.state)) {
      return (<OrderSummaryContainer
        clothes={this.getClothesForSummary()}
        setEditingCloth={this.setEditingCloth}
        saveCloth={this.saveCloth}
        onTotalPaid={totalPaid => this.trackNeedsDescribed(totalPaid)}
        selectedFabricOfAccessoriesCreation={this.state.selectedFabricOfAccessoriesCreation}
      />);
    } else if (clothes.length) {
      return (<OrderSummaryContainer
        canEdit
        clothes={this.getSelectedClothesForSummary()}
        setEditingCloth={this.setEditingCloth}
        saveCloth={this.saveCloth}
        selectedFabricOfAccessoriesCreation={this.state.selectedFabricOfAccessoriesCreation}
      />);
    } return '';
  }

  renderButtons() {
    const { selectedCategory, displayButtons } = this.state;
    return selectedCategory !== 'RDV3Clicks' && (isSelectingDone(this.state) || displayButtons) && (
      <StepContainer2>
        <ComponentsContainer>
          <StyledButton onClick={this.addPiece} transparent isAvailable>
            + Ajouter une pièce
          </StyledButton>
          <StyledLink onClick={this.goNext}>
            <ArrowButton>OK, on passe au RDV !</ArrowButton>
          </StyledLink>
        </ComponentsContainer>
      </StepContainer2>
    );
  }

  renderStep1Form() {
    const { selectedTypeOfWork, selectedCloth, selectedProblems } = this.state;
    const isLiningDisplayed = !['acce', 'cost'].includes(selectedCloth) ? shouldDisplayLining(selectedProblems) : false;
    return (
      <Step1Form
        isLiningDisplayed={isLiningDisplayed}
        {...this.getGeneralProps()}
        {...this.getAccessoryProps()}
        setRef={(key, value) => { this[key] = value; }}
        selectCategory={this.selectCategory}
        selectCloth={this.selectCloth}
        selectPiece={this.selectPiece}
        selectFabric={this.selectFabric}
        selectTypeOfWork={this.selectTypeOfWork}
        selectAccessoryCreation={this.selectAccessoryCreation}
        selectAccessoryCreationsOptionsForBags={this.selectAccessoryCreationsOptionsForBags}
        selectProblems={this.selectProblems}
        treeProblems={getTreeProblems(tree, selectedCloth, selectedTypeOfWork)}
        selectLocations={this.selectLocations}
        getTreeLocations={problemSlug => getTreeLocations(tree, selectedCloth, selectedTypeOfWork, problemSlug)}
        setSelectValue={this.setSelectValue}
        selectLining={this.selectLining}
        setSelect={this.setSelect}
        setSingleChoice={this.setSingleChoice}
        setMultipleChoices={this.setMultipleChoices}
        {...this.getCurtainProps()}
        {...this.getCurtainProps()}
        {...this.getEmbroideryProps()}
        {...this.getLinenProps()}
        {...this.getSuitProps()}
        displaySummary={!!this.displaySummary()}
        selectFabricOfAccessoriesCreation={this.selectFabricOfAccessoriesCreation}
        {...this.getUpcyclingProps()}
        toggleUpcyclingCategory={this.toggleUpcyclingCategory}
        toggleUpcyclingItem={this.toggleUpcyclingItem}
        setUpcyclingItemQuantity={this.setUpcyclingItemQuantity}
        toggleSuitPiece={this.toggleSuitPiece}
        toggleSuitProblem={this.toggleSuitProblem}
        toggleSuitItem={this.toggleSuitItem}
        setSuitItemQuantity={this.setSuitItemQuantity}
        selectSuitPieceLining={this.selectSuitPieceLining}
      />
    );
  }

  render() {
    return (
      <StepContainer1>
        <Content>
          {this.renderStep1Form()}
          {this.displaySummary()}
          {this.renderButtons()}
        </Content>
      </StepContainer1>
    );
  }
}

Step1.propTypes = {
  orderContext: PropTypes.shape({
    addOrEditCloth: PropTypes.func.isRequired,
    clothes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    editingCloth: PropTypes.shape({}),
    editingClothIndex: PropTypes.number,
    rdv3ClicksOrder: PropTypes.shape({
      knowHow: PropTypes.array,
    }),
    setRDV3ClicksOrder: PropTypes.func,
  }).isRequired,
  initialState: PropTypes.shape({
    selectedCategory: PropTypes.string,
    selectedPiece: PropTypes.string,
    selectedCloth: PropTypes.string,
    selectedFabric: PropTypes.string,
    selectedTypeOfWork: PropTypes.string,
  }),
};

Step1.defaultProps = {
  initialState: {},
};

export default withOrderContext(Step1);
