import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  StepContainer, TreeRow, Label, Row2, SmallContent, Question, AnswerCard, mobileThresholdPixels,
} from './orderStyledComponents';
import ProgressBarVertical from '../order/ProgressBarVertical';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
  }
`;

const UpcyclingCategoriesSelector = ({ tree, upcyclingCategories, toggleUpcyclingCategory }) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical
        isActive={upcyclingCategories.length > 0}
        height={tree.answers.length < 4 ? 146 : 200}
        noLine={upcyclingCategories.length === 0}
      />
      <SmallContent flexStart marginLeft>
        <Question>{tree.question}</Question>
        <Row2>
          <CardsContainer>
            {tree.answers.map(({ slug, label }) => (
              <AnswerCard
                key={slug}
                slug={slug}
                onClick={() => toggleUpcyclingCategory(slug)}
                isActive={upcyclingCategories.includes(slug)}
              >
                <Label>
                  {label}
                </Label>
              </AnswerCard>),
            )}
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

UpcyclingCategoriesSelector.propTypes = {
  tree: PropTypes.shape({}).isRequired,
  upcyclingCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleUpcyclingCategory: PropTypes.func.isRequired,
};

export default UpcyclingCategoriesSelector;
