import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import iconInformationNavy from '../../assets/order/iconInformationNavy.svg';
import { mobileThresholdPixels, colors } from '../styledComponents';
import {
  StepContainer,
  TreeRow,
  SmallContent,
  Question,
  Label,
  Row2,
  AnswerCard,
  CardsContainer,
} from './orderStyledComponents';
import ProgressBarVertical from '../order/ProgressBarVertical';

const embroideryFontSizes = [
  'Petite police',
  'Grosse police',
];

const fullFontEmbroideryPieces = ['pant', 'pull', 'mant', 'haut', 'deco'];
const getEmbroideryFontSizes = pieceSlug =>
  fullFontEmbroideryPieces.includes(pieceSlug)
    ? [...embroideryFontSizes, 'Grosse police pleine']
    : embroideryFontSizes;

const embroideryDrawingSizes = [
  '< 5 cm',
  '< 12 cm',
  '< 25 cm',
];

const FlexContainer = styled.div`
  display: flex;
  @media (max-width: ${mobileThresholdPixels}) {
    justify-content: center;
  }
`;

const QuestionNoPadding = styled(Question)`
  @media (max-width: ${mobileThresholdPixels}) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const Tooltip = styled(ReactTooltip)`
  margin: 15px;
  width: 245px;
  border-radius: 4px;
  opacity: 1 !important;
  background-color: ${colors.navy} !important;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 203px;
  }
`;

const TooltipText = styled.p`
  font-family: Roboto;
  color: ${colors.white};
  width: 215px;
  line-height: 15px;
  font-weight: normal;
  font-size: 13px;
  text-align: left;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 175px;
    font-size: 12px;
  }
`;

const Img = styled.img`
  margin-left: 8px;
  margin-bottom: 8px;
`;

const renderTooltip = () => (
  <div>
    <Tooltip place="right" type="none" effect="float" id="tooltipEmbroideries">
      <TooltipText>
        Les broderies simples sont par exemple en forme de :
        coeur - triangle - vague...
      </TooltipText>
      <TooltipText>
        Tandis que les broderies complexes peuvent avoir des formes comme :
        oiseau - palmier - chat...
      </TooltipText>
    </Tooltip>
    {' '}
    <Img
      src={iconInformationNavy}
      alt="Information"
      data-tip
      data-for="tooltipEmbroideries"
    />
  </div>
);

const Step1Embroideries = ({
  setSingleChoice, setMultipleChoices, pieceSlug,
  embroideries, activeEmbroideryFontSize, activeEmbroideryDrawingSize,
  isDrawingFull, isDrawingEasy, displaySummary,
}) => (
  <div>
    <TreeRow mobilePaddingRight={0}>
      <StepContainer>
        <ProgressBarVertical
          isActive={(embroideries.filter(embrodery => embrodery.isActive === true).length > 0)}
          height={200}
          noLine={
            (!embroideries[0].isActive
              && !embroideries[1].isActive
              && !embroideries[2].isActive
              && !embroideries[3].isActive) ||
            ((embroideries[0].isActive || embroideries[3].isActive) &&
            (!embroideries[1].isActive && !embroideries[2].isActive))
          }
        />
        <SmallContent mobileNoMarginTop mobileFullWidth>
          <Question center>Indiquez-nous le type de broderie :</Question>
          <Row2>
            <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
              {embroideries.map((embroidery, index) => (
                <AnswerCard
                  key={embroidery.label}
                  isActive={embroidery.isActive}
                  onClick={() => setMultipleChoices(index, 'embroideries')}
                >
                  <Label>{embroidery.label}</Label>
                </AnswerCard>
              ))}
            </CardsContainer>
          </Row2>
        </SmallContent>
      </StepContainer>
    </TreeRow>

    {embroideries[1].isActive &&
      <TreeRow mobilePaddingRight={0}>
        <StepContainer>
          <ProgressBarVertical
            isActive={displaySummary || activeEmbroideryFontSize > -1}
            height={141}
            noLine={!embroideries[2].isActive && activeEmbroideryFontSize >= -1}
          />
          <SmallContent mobileNoMarginTop mobileFullWidth>
            <Question center>Comment serait votre police ?</Question>
            <Row2>
              <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                {getEmbroideryFontSizes(pieceSlug).map((embroideryFontSize, index) => (
                  <AnswerCard
                    key={embroideryFontSize}
                    isActive={index === activeEmbroideryFontSize}
                    onClick={() => setSingleChoice(index, 'activeEmbroideryFontSize')}
                  >
                    <Label>{embroideryFontSize}</Label>
                  </AnswerCard>
                ))}
              </CardsContainer>
            </Row2>
          </SmallContent>
        </StepContainer>
      </TreeRow>
    }

    {embroideries[2].isActive &&
      <TreeRow mobilePaddingRight={0}>
        <StepContainer>
          <ProgressBarVertical
            isActive={activeEmbroideryDrawingSize > -1}
            height={141}
          />
          <SmallContent mobileNoMarginTop mobileFullWidth>
            <Question>Quelle est la taille de votre dessin ?</Question>
            <Row2>
              <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                {embroideryDrawingSizes.map((embroideryDrawingSize, index) => (
                  <AnswerCard
                    key={embroideryDrawingSize}
                    isActive={index === activeEmbroideryDrawingSize}
                    onClick={() => setSingleChoice(index, 'activeEmbroideryDrawingSize')}
                  >
                    <Label>{embroideryDrawingSize}</Label>
                  </AnswerCard>
                ))}
              </CardsContainer>
            </Row2>
          </SmallContent>
        </StepContainer>
      </TreeRow>
    }

    {embroideries[2].isActive && activeEmbroideryDrawingSize !== -1 &&
      <TreeRow mobilePaddingRight={0}>
        <StepContainer>
          <ProgressBarVertical
            isActive={isDrawingFull !== undefined}
            height={141}
          />
          <SmallContent mobileNoMarginTop mobileFullWidth>
            <Question>Quel est le type de dessin ?</Question>
            <Row2>
              <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                <AnswerCard
                  isActive={isDrawingFull === false}
                  onClick={() => setSingleChoice(false, 'isDrawingFull')}
                >
                  <Label>Dessin creux</Label>
                </AnswerCard>
                <AnswerCard
                  isActive={isDrawingFull === true}
                  onClick={() => setSingleChoice(true, 'isDrawingFull')}
                  isAvailable={activeEmbroideryDrawingSize !== 2}
                >
                  <Label>Dessin plein</Label>
                </AnswerCard>
              </CardsContainer>
            </Row2>
          </SmallContent>
        </StepContainer>
      </TreeRow>
    }

    {embroideries[2].isActive && typeof isDrawingFull === 'boolean' && activeEmbroideryDrawingSize !== -1 &&
      <TreeRow mobilePaddingRight={0}>
        <StepContainer>
          <ProgressBarVertical
            isActive={isDrawingEasy !== undefined}
            height={141}
            noLine
          />
          <SmallContent mobileNoMarginTop mobileFullWidth>
            <FlexContainer>
              <QuestionNoPadding>
                {'Comment serait la forme de votre dessin ?'}
              </QuestionNoPadding>
              {renderTooltip()}
            </FlexContainer>
            <Row2>
              <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                <AnswerCard
                  isActive={isDrawingEasy}
                  onClick={() => setSingleChoice(true, 'isDrawingEasy')}
                >
                  <Label>Simple</Label>
                </AnswerCard>
                <AnswerCard
                  isActive={isDrawingEasy === false}
                  onClick={() => setSingleChoice(false, 'isDrawingEasy')}
                >
                  <Label>Complexe</Label>
                </AnswerCard>
              </CardsContainer>
            </Row2>
          </SmallContent>
        </StepContainer>
      </TreeRow>
    }
  </div>
);

Step1Embroideries.propTypes = {
  setSingleChoice: PropTypes.func.isRequired,
  setMultipleChoices: PropTypes.func.isRequired,
  embroideries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeEmbroideryFontSize: PropTypes.number.isRequired,
  activeEmbroideryDrawingSize: PropTypes.number.isRequired,
  isDrawingFull: PropTypes.bool,
  isDrawingEasy: PropTypes.bool,
  pieceSlug: PropTypes.string.isRequired,
  displaySummary: PropTypes.bool,
};

Step1Embroideries.defaultProps = {
  isDrawingFull: undefined,
  isDrawingEasy: undefined,
  displaySummary: false,
};

export default Step1Embroideries;
