import styled from 'styled-components';
import { mobileThresholdPixels, colors } from './styledComponents';
import { Button } from './home/v2/styledComponents';
import { StepContainer as StepContainerV3, colors as colorsV3 } from './home/v3/styledComponents';

export const StepContainer1 = styled.div`
  display: block;
  background-color: ${colors.lightGrey};
  `;

export const StepContainer2 = styled(StepContainerV3)`
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: 45px auto auto;
  }
`;

export const Content = styled.div`
  display: block;
  margin: auto;
`;

export const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  ${props => !props.isAvailable && `
    border-color: ${colorsV3.lightGrey3};
    color: ${colorsV3.lightGrey3};
    pointer-events: none;
    cursor: unset;`}

  @media (max-width: ${mobileThresholdPixels}) {
    height: 45px;
    font-size: 13px;
    min-width: 210px;
    padding: 10px;
    margin: 10px 37px 0px;
    ${props => props.mobileOrder && 'margin-top: 0px;'}
  }
`;

export const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;
