import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withAppContext from '../../withAppContext';
import { Row, Subtitle, Content, CardsContainer } from './orderStyledComponents';
import { mobileThresholdPixels, colors } from '../styledComponents';
import Dots from '../home/v3/Dots';
import CardSelector from './CardSelector';
import fabrics from './fabrics';

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: regular;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${colors.navy};
  margin: 40px 37px 0px 37px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 290px;
    margin: 20px auto auto;
    line-height: 17px;
    font-size: 14px;
  }
`;

const mustHideFabric = (slug, cloth) => (
  (slug === 'leather' && cloth === 'acce')
  || (slug === 'suit' && cloth === 'haut')
  || (slug === 'fur' && ['haut', 'pant', 'robe', 'jupe'].includes(cloth))
  || (slug === 'bath' && ['robe', 'jupe'].includes(cloth))
);

const Step1Fabrics = ({ context: { isMobile }, selectFabric, selectedFabric, selectedCloth, isFurSelected }) => (
  <Row mobileMarginTop={0.01}>
    <Content marginTop={7}>
      <Subtitle>Une matière particulière ?</Subtitle>
      <Dots justifyContent="center" noMargin />
      <Text>Certaines matières nécessitent un savoir-faire et des outils spécifiques.
        {isMobile ? ' ' : <br />}
        Si besoin, nous vous enverrons un spécialiste. Pas d’inquiétude, le prix reste le même.
      </Text>
      <Row2>
        <CardsContainer>
          {fabrics
            .filter(({ slug }) => !mustHideFabric(slug, selectedCloth))
            .map(({ slug, name, image, filterContent }) => (
              <CardSelector
                key={slug}
                slug={slug}
                image={image}
                label={(isMobile && slug === 'other') ? 'Autre' : name}
                alt={`Tilli retouche vos vêtements en ${name}`}
                onClick={selectFabric}
                littleCard
                active={selectedFabric === slug}
                filterContent={filterContent}
                isFurSelected={isFurSelected}
              />
            ))}
        </CardsContainer>
      </Row2>
    </Content>
  </Row>
);

Step1Fabrics.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  selectFabric: PropTypes.func,
  selectedFabric: PropTypes.string,
  selectedCloth: PropTypes.string,
  isFurSelected: PropTypes.bool.isRequired,
};

Step1Fabrics.defaultProps = {
  selectedFabric: '',
  selectFabric() { },
  selectedCloth: '',
};

export default withAppContext(Step1Fabrics);
