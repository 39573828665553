import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  customStyles, setTheme, StepContainer, Select, TreeRow, Row2, SmallContent, Question,
} from './orderStyledComponents';
import { mobileThresholdPixels } from '../styledComponents';
import { multipleQuestions } from '../order/tree';
import ProgressBarVertical from '../order/ProgressBarVertical';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 243px;
    justify-content: center;
  }
`;

const Step1Multiple = ({ createRange, slug, setSelectValue, selectedValue, isLast, itemId }) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical
        isActive={Object.keys(selectedValue).length && selectedValue.value > 0}
        height={142}
        noLine={isLast && !!selectedValue}
      />
      <SmallContent flexStart marginLeft>
        <Question>{multipleQuestions[slug.replace('_multiple', '')]}</Question>
        <Row2>
          <CardsContainer>
            <Select
              placeholder=""
              value={selectedValue}
              onChange={valueSelected => setSelectValue(valueSelected, itemId || slug)}
              options={createRange(1, 8)}
              styles={customStyles}
              theme={theme => setTheme(theme)}
            />
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

Step1Multiple.propTypes = {
  createRange: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  setSelectValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.shape({}).isRequired,
  isLast: PropTypes.bool.isRequired,
  itemId: PropTypes.string,
};

Step1Multiple.defaultProps = {
  isLast: false,
  itemId: undefined,
};

export default Step1Multiple;
