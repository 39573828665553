import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  StepContainer, TreeRow, Label, Row2, SmallContent, Question, AnswerCard, mobileThresholdPixels,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';
import { treeAlterationSuit } from './tree';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 243px;
  }
`;

const SuitItemsSelector = ({ suitPiece, suitProblem, suitItems, toggleSuitItem }) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical
        isActive={suitItems.length > 0}
        height={treeAlterationSuit.pieces[suitPiece].problems[suitProblem].answers.length > 5 ? 250 : 200}
      />
      <SmallContent flexStart marginLeft>
        <Question>
          {treeAlterationSuit.pieces[suitPiece].problems[suitProblem].question}
        </Question>
        <Row2>
          <CardsContainer>
            {treeAlterationSuit.pieces[suitPiece].problems[suitProblem].answers.map(item => (
              <AnswerCard
                key={item.id}
                onClick={() => toggleSuitItem(suitPiece, suitProblem, item)}
                isActive={!!suitItems.find(({ id }) => id === item.id)}
              >
                <Label>
                  {item.label}
                </Label>
              </AnswerCard>
            ))}
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

SuitItemsSelector.propTypes = {
  suitPiece: PropTypes.string.isRequired,
  suitProblem: PropTypes.string.isRequired,
  suitItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleSuitItem: PropTypes.func.isRequired,
};

export default SuitItemsSelector;
