import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  TreeRow,
  StepContainer,
  SmallContent,
  Question,
  Label,
  Row2,
  AnswerCard,
  colors,
  customStyles,
  setTheme,
  Select,
  mobileThresholdPixels,
  CardsContainer as CardsContainerOrder,
} from './orderStyledComponents';
import ProgressBarVertical from './ProgressBarVertical';
import { treeCreation } from './tree';

const TipContainer = styled.div`
  position: absolute;
`;

const TipText = styled.p`
  position: relative;
  top: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${colors.navy};
  margin-left: 15px;
`;

const CardsContainer = styled(CardsContainerOrder)`
  margin-top: 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.littleCard ? '170px' : '210px')};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Img = styled.img`
  border-radius: 3px 3px 0px 0px;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;

  ${({ showFilter }) => showFilter && 'opacity: 0.7;'}
`;

const AnswerCardImg = styled.div`
  cursor: pointer;
  margin: 0px 15px 10px 15px;
  width: 240px;

  ${props =>
    props.isAvailable !== undefined &&
    !props.isAvailable &&
    `color: ${colors.blueGrey}; pointer-events: none;`}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 2.5px 0px 2.5px 0px;
    width: 100%;

    ${props => props.extraMarginBottom && 'margin-bottom: 25px;'};
  }
`;

const LabelImg = styled.button`
  background-color: ${props => (props.isActive ? colors.navy : colors.white)};
  border: 0.75px solid;
  border-color: ${props => (props.isActive ? colors.navy : colors.blueGrey)};
  border-radius: 0px 0px 3px 3px;
  box-sizing: border-box;
  color: ${props => (props.isActive ? colors.white : colors.navy)};
  cursor: pointer;
  height: 42px;
  justify-content: center;
  outline: 0;
  transition: all 0.5s ease;
  width: 100%;

  ${props =>
    props.isAvailable !== undefined &&
    !props.isAvailable &&
    `color: ${colors.blueGrey}; pointer-events: none;`}
`;

const renderQuestionAndAnswers = (
  question,
  answers = [],
  selectedAnswer = {},
  selectAnswer,
  stateNameForClick,
  noLine = false,
) => {
  const show = answers[0].image !== undefined;
  const height = show ? 310 : 140;

  return (
    <TreeRow>
      <StepContainer>
        <ProgressBarVertical
          isActive={Object.keys(selectedAnswer).length > 0}
          height={height}
          noLine={noLine}
        />
        <SmallContent marginBottom={'30px'} mobileNoMarginTop mobileFullWidth>
          <Question center>{question}</Question>
          <Row2 noMarginBottomMobile>
            {show ? (
              <CardsContainer>
                {answers.map(answer => (
                  <AnswerCardImg
                    key={answer.slug}
                    onClick={() => selectAnswer(stateNameForClick, answer)}
                  >
                    <ImgContainer littleCard>
                      <Img src={answer.image} />
                    </ImgContainer>
                    <LabelImg isActive={selectedAnswer.slug === answer.slug}>
                      <Label>{answer.label}</Label>
                    </LabelImg>
                  </AnswerCardImg>
                ))}
              </CardsContainer>
            ) : (
              <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
                {answers.map(answer => (
                  <AnswerCard
                    key={answer.slug}
                    isActive={selectedAnswer.slug === answer.slug}
                    onClick={() => selectAnswer(stateNameForClick, answer)}
                    extraMarginBottom={answer.label.includes('*')}
                  >
                    <Label>{answer.label}</Label>
                    {answer.label.includes('*') && (
                      <TipContainer>
                        <TipText>
                          {"*À vous de nous fournir l'anse de votre choix :)"}
                        </TipText>
                      </TipContainer>
                    )}
                  </AnswerCard>
                ))}
              </CardsContainer>
            )}
          </Row2>
        </SmallContent>
      </StepContainer>
    </TreeRow>
  );
};

const renderSelect = (
  createRange,
  numberOfPieces = 0,
  selectAnswer,
  stateNameForClick,
) => (
  <TreeRow>
    <StepContainer>
      <ProgressBarVertical isActive={numberOfPieces > 0} height={141} />
      <SmallContent marginBottom={'30px'} mobileNoMarginTop>
        <Question center>Combien de housses ?</Question>
        <Row2 noMarginBottomMobile>
          <CardsContainer mobileNoMarginTop mobileCenter marginTop={5}>
            <Select
              placeholder=""
              value={{ label: numberOfPieces, value: numberOfPieces }}
              onChange={selectedValue =>
                selectAnswer(stateNameForClick, selectedValue.value)
              }
              options={createRange(1, 10)}
              styles={customStyles}
              theme={theme => setTheme(theme)}
            />
          </CardsContainer>
        </Row2>
      </SmallContent>
    </StepContainer>
  </TreeRow>
);

const typeSlugs = {
  bag: 'crea_sac',
  toteBag: 'crea_tote',
  clutchBag: 'crea_poch',
  cou2: 'crea_hous',
};

function getTypeSlug(selectedBagType) {
  return typeSlugs[selectedBagType];
}

const Step1Creations = ({
  selectedBagType,
  selectedBagShape,
  selectedBagSize,
  selectedBagHandle,
  selectedBagClosing,
  selectedCreationNumberOfPieces,
  createRange,
  selectAccessoryCreationsOptionsForBags,
  noHandleOption,
}) => {
  const isCushion = selectedBagType === 'cou2';
  const typeSlug = getTypeSlug(selectedBagType);
  const questions = treeCreation.find(cloth => cloth.slug === typeSlug) // TODO use selectedBagType
    .questions;
  const shapeQuestion = questions.find(question => question.slug === 'shape');
  const sizeQuestion = questions.find(question => question.slug === 'size');
  const handleQuestion = {
    ...questions.find(question => question.slug === 'handle'),
  };
  const handleAnswers = noHandleOption
    ? handleQuestion?.answers
    : handleQuestion?.answers?.filter(answer => answer.slug !== 'noHandle');
  handleQuestion.answers = handleAnswers;
  const closingQuestion = questions.find(
    question => question.slug === 'closing',
  );

  return (
    <div>
      {isCushion &&
        renderSelect(
          createRange,
          selectedCreationNumberOfPieces,
          selectAccessoryCreationsOptionsForBags,
          'selectedCreationNumberOfPieces',
        )}

      {(!isCushion || selectedCreationNumberOfPieces) &&
        renderQuestionAndAnswers(
          shapeQuestion.label,
          shapeQuestion.answers,
          selectedBagShape,
          selectAccessoryCreationsOptionsForBags,
          'selectedBagShape',
        )}

      {!!Object.keys(selectedBagShape).length &&
        renderQuestionAndAnswers(
          sizeQuestion.label,
          sizeQuestion.answers,
          selectedBagSize,
          selectAccessoryCreationsOptionsForBags,
          'selectedBagSize',
        )}

      {!!Object.keys(selectedBagSize).length &&
        !isCushion &&
        renderQuestionAndAnswers(
          handleQuestion.label,
          handleQuestion.answers,
          selectedBagHandle,
          selectAccessoryCreationsOptionsForBags,
          'selectedBagHandle',
        )}

      {!!(
        Object.keys(selectedBagHandle).length ||
        (isCushion && Object.keys(selectedBagSize).length)
      ) &&
        renderQuestionAndAnswers(
          closingQuestion.label,
          closingQuestion.answers,
          selectedBagClosing,
          selectAccessoryCreationsOptionsForBags,
          'selectedBagClosing',
          true,
        )}
    </div>
  );
};

Step1Creations.propTypes = {
  selectedBagType: PropTypes.string.isRequired,
  selectedBagShape: PropTypes.shape({}).isRequired,
  selectedBagSize: PropTypes.shape({}).isRequired,
  selectedBagHandle: PropTypes.shape({}).isRequired,
  selectedBagClosing: PropTypes.shape({}).isRequired,
  selectedCreationNumberOfPieces: PropTypes.number.isRequired,
  selectAccessoryCreationsOptionsForBags: PropTypes.func.isRequired,
  createRange: PropTypes.func.isRequired,
  noHandleOption: PropTypes.bool,
};

Step1Creations.defaultProps = {
  noHandleOption: false,
};

export default Step1Creations;
