import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  StepContainer, TreeRow, Label, Row2, SmallContent, Question, AnswerCard, mobileThresholdPixels,
} from './orderStyledComponents';
import ProgressBarVertical from '../order/ProgressBarVertical';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 976px;
  margin-top: 20px 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
  }
`;

const Step1Where = ({ treeLocations, treeLocations: { locations }, selectLocations, selectedLocations }) => {
  const progressBarHeight = (height) => {
    height = 200;
    if (locations.length < 3) {
      height = 141;
    } else if (locations.length > 5) {
      height = 250;
    } return height;
  };
  return (
    <TreeRow>
      <StepContainer>
        <ProgressBarVertical
          isActive={Object.keys(selectedLocations).length > 0}
          height={progressBarHeight()}
        />
        <SmallContent flexStart marginLeft>
          <Question>{treeLocations.question}</Question>
          <Row2>
            <CardsContainer>
              {locations.map(location =>
                <AnswerCard
                  key={location.slug}
                  slug={location.slug}
                  onClick={() => selectLocations(location.slug)}
                  isActive={selectedLocations.includes(location.slug)}
                >
                  <Label>
                    {location.label}
                  </Label>
                </AnswerCard>,
              )}
            </CardsContainer>
          </Row2>
        </SmallContent>
      </StepContainer>
    </TreeRow>
  );
};

Step1Where.propTypes = {
  selectLocations: PropTypes.func,
  selectedLocations: PropTypes.arrayOf(PropTypes.string),
  treeLocations: PropTypes.shape({
    locations: PropTypes.array,
  }),
};

Step1Where.defaultProps = {
  selectLocations() { },
  selectedLocations: [],
  treeLocations: {},
};

export default Step1Where;
